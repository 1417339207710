<template>
  <div
    v-if="experience"
    class="mt-2 md:mt-0 md:px-0 container max-w-8xl mx-auto relative"
  >
    <div v-if="!experience.flags.complete">
      <div
        class="obk-nha-form obk-nha container max-w-8x bg-white border border-nColorStrokeGray rounded-xl mx-auto font-Rajdhani bg-no-repeat relative bg-contain z-0"
      >
        <div class="user-profile mt-4 md:mt-24 grid-2 place-items-center">
          <div class="grid-item -ml-6 md:ml-0">
            <div
              class="upload-box-container border inline-block border-gray-200 p-3 relative"
            >
              <div class="yellow upload-box">
                <div
                  class="box-content h-full flex justify-center items-center text-color"
                >
                  <input
                    type="file"
                    name="profile-image"
                    id="profile-image"
                    @change="setProfileImage($event)"
                  />
                  <div class="upload-profile text-center" v-if="!profileImage">
                    <label for="profile-image" class="cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-16 w-16 yellow-btn inline-block"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p class="text-sm tracking-wide">
                        Click to add Profile Picture
                      </p>
                    </label>
                  </div>
                  <div class="profile-image relative" v-else>
                    <img
                      class="upload-box object-contain"
                      ref="profileImage"
                      :src="profileImage"
                      alt=""
                    />
                    <label
                      for="profile-image"
                      class="cursor-pointer absolute top-0 right-0 block"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                        />
                      </svg>
                    </label>
                  </div>
                </div>
              </div>

              <div
                class="label bg-nColorMustard text-white absolute top-20 -right-10 text-2xl p-2 hidden md:block"
              >
                <p>Hi there! I'm</p>
              </div>
              <div
                class="label bg-nColorMustard text-white absolute bottom-10 -right-10 text-lg px-2 block md:hidden"
              >
                <p>Hi there! I'm</p>
              </div>
            </div>
          </div>

          <div class="user-name md:p-3 mt-4 md:mt-0">
            <p
              class="title font-Fancy yellow-text mb-12 md:mb-20"
              v-if="profileData.user_name"
            >
              {{ profileData.user_name }}
            </p>
            <textarea
              v-model="profileData.quote"
              class="rounded p-2 text-color resize-none yellow-field border text-sm md:text-lg"
              name="familyDetails"
              id="familyDetails"
              cols="40"
              rows="2"
              placeholder="Enter your favorite quote"
            ></textarea>
          </div>
        </div>

        <div class="user-work-details grid-2 mt-2 md:mt-24">
          <CircleContainer color="yellow" class="">
            <div class="dob mb-6">
              <p>
                Born on
                <DatePicker
                  class="md:inline-block h-full"
                  v-model="profileData.personal.dob"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div class="flex items-center">
                      <input
                        :value="inputValue"
                        class="bg-white text-gray-700 w-36 px-2 py-1 md:py-0 appearance-none rounded-l yellow-field text-md"
                        readonly
                      />
                      <button
                        class="p-1.5 bg-yellow-200 text-yellow-500 rounded-r focus:bg-yellow-500 focus:text-white focus:border-yellow-500 focus:outline-none"
                        @click="togglePopover()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          class="w-5 h-5 fill-current"
                        >
                          <path
                            d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </template>
                </DatePicker>
              </p>
            </div>

            <div class="pos mb-6">
              <p class="mb-2">
                I am a
                <input
                  class="mx-2 rounded w-48 py-0.5 md:py-0 md:w-56 pl-2 yellow-field"
                  placeholder="Frontend Engineer"
                  type="text"
                  v-model="profileData.personal.designation"
                />
              </p>
              <p>
                based out of
                <input
                  class="mx-2 rounded w-36 md:w-48 py-0.5 md:py-0 pl-2 yellow-field"
                  placeholder="Malaysia"
                  type="text"
                  v-model="profileData.personal.based_out_of"
                />
              </p>
            </div>

            <div class="exp mb-6">
              <p>
                I have
                <input
                  class="mx-2 rounded w-8 pl-2 py-0.5 md:py-0 yellow-field"
                  placeholder="99"
                  type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  v-model="profileData.personal.years_of_exp"
                />
                years
              </p>
              <p>of experience and</p>
            </div>

            <div class="prev-company">
              <p>my last stint was at</p>
              <p>
                <input
                  class="mx-2 rounded w-36 md:w-56 py-0.5 md:py-0 text-center yellow-field"
                  placeholder="Morph Digital Solutions"
                  type="text"
                  v-model="profileData.personal.last_job"
                />
              </p>
            </div>
          </CircleContainer>
          <div
            class="user-name md:p-3 flex flex-col justify-between items-center"
          >
            <img
              class="w-72 illustration"
              :src="require('@/assets/images/nha/stairs.svg')"
              alt=""
            />
            <span class="title font-Fancy green-text mt-2 md:mt-0"
              >Personal</span
            >
          </div>
        </div>

        <div class="personal-gif grid-2 md:mt-8">
          <div class="personal-illu flex items-center">
            <img
              class="w-96 mx-auto illustration hidden md:block"
              :src="require('@/assets/images/nha/family.svg')"
              alt=""
            />
          </div>

          <div class="grid-item flex justify-center">
            <GIFContainer color="green" v-if="!profileData.personal.animation">
              <GIFAddButton color="green" @click="openModal('personal')" />
            </GIFContainer>

            <div v-else class="relative">
              <svg
                @click="openModal('personal')"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                />
              </svg>
              <img
                class="upload-box object-contain border p-3"
                :src="profileData.personal.animation"
                alt=""
              />
            </div>
          </div>
          <div class="flex items-center md:hidden">
            <img
              class="w-96 mx-auto illustration"
              :src="require('@/assets/images/nha/family.svg')"
              alt=""
            />
          </div>
        </div>

        <div class="personal-details grid-2 mt-2 md:mt-24">
          <CircleContainer color="green">
            <div class="mb-6">
              <p class="mb-4">My family consists of</p>

              <textarea
                v-model="profileData.personal.family_info"
                class="rounded p-2 resize-none green-field"
                name="familyDetails"
                id="familyDetails"
                cols="32"
                rows="2"
                placeholder="my dad, my wife Meena, 2 kids and Cherry our golden retreiver"
              ></textarea>
            </div>
          </CircleContainer>
          <div
            class="personal-illu flex flex-col justify-evenly text-white font-Fancy items-center"
          >
            <img
              class="w-60 illustration"
              :src="require('@/assets/images/nha/phone.svg')"
              alt=""
            />

            <div class="rounded-input w-full rounded-full mt-4 md:mt-0">
              <input
                placeholder="< Enter Social Media Link >"
                class="social-link solid-green w-80 md:w-full px-5 pb-1 pt-4 text-center rounded-full text-2xl md:text-4xl placeholder-gray-200 text-white"
                type="url"
                name="social"
                id="social"
                v-model="profileData.personal.social_media_link"
              />
            </div>
          </div>
        </div>

        <div class="interests grid-2 mt-24">
          <div class="grid-item flex justify-center">
            <GIFContainer color="pink" v-if="!profileData.interests.animation">
              <GIFAddButton color="pink" @click="openModal('interests')" />
            </GIFContainer>

            <div v-else class="relative">
              <svg
                @click="openModal('interests')"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                />
              </svg>
              <img
                class="upload-box object-contain border p-3"
                :src="profileData.interests.animation"
                alt=""
              />
            </div>
          </div>
          <div class="interest-header flex flex-col justify-end">
            <span class="title font-Fancy pink-text">Interests</span>
          </div>
        </div>

        <div class="aspire mt-2 md:mt-24 grid-2">
          <CircleContainer color="pink" class="order-2 md:order-1">
            <div class="asp mb-10">
              <p>When I was little</p>
              <p>I wanted to be a</p>
              <p>
                <input
                  class="mx-2 rounded w-48 py-0.5 md:py-0 text-center pink-field"
                  placeholder="Cricketer"
                  type="text"
                  v-model="profileData.interests.wanted_to_be"
                />
              </p>
            </div>

            <div class="learn mb-10">
              <p>In the last year</p>
              <p>I learned to</p>
              <p>
                <input
                  class="mx-2 rounded w-56 py-0.5 md:py-0 text-center pink-field"
                  placeholder="How to cook"
                  type="text"
                  v-model="profileData.interests.learned_last_year"
                />
              </p>
            </div>
          </CircleContainer>

          <div
            class="link-illu font-Fancy flex md:flex-col justify-center md:justify-between order-1 md:order-2"
          >
            <img
              class="w-60 md:w-72 illustration"
              :src="require('@/assets/images/nha/children.svg')"
              alt=""
            />
          </div>
        </div>

        <div class="favorites mt-24 grid-2">
          <div class="grid-item flex justify-center">
            <GIFContainer
              color="purple"
              v-if="!profileData.favorites.animation"
            >
              <GIFAddButton color="purple" @click="openModal('favorites')" />
            </GIFContainer>
            <div v-else class="relative">
              <svg
                @click="openModal('favorites')"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                />
              </svg>
              <img
                class="upload-box object-contain border p-3"
                :src="profileData.favorites.animation"
                alt=""
              />
            </div>
          </div>

          <div class="interest-header flex flex-col justify-end">
            <span class="title font-Fancy purple-text">Favorites</span>
          </div>
        </div>

        <div class="favorites-form grid-2 mt-2 md:mt-24">
          <div
            class="image-container flex flex-col justify-center items-center"
          >
            <img
              class="w-36 md:w-48 illustration"
              :src="require('@/assets/images/nha/working.svg')"
              alt=""
            />
          </div>

          <CircleContainer color="purple">
            <div class="book mb-3 md:mb-6">
              <p>Book I love</p>
              <p>
                <input
                  class="mx-2 rounded w-32 py-0.5 md:py-0 text-center purple-field"
                  placeholder="Harry potter"
                  type="text"
                  v-model="profileData.favorites.book"
                />
              </p>
            </div>

            <div class="binge-tv mb-3 md:mb-6">
              <p>My comfort binge TV show</p>
              <p>
                <input
                  class="mx-2 rounded w-48 py-0.5 md:py-0 text-center purple-field"
                  placeholder="Game of Thrones"
                  type="text"
                  v-model="profileData.favorites.tv_show"
                />
              </p>
            </div>

            <div class="food mb-3 md:mb-6">
              <p>My favorite kinda food</p>
              <p>
                <input
                  class="mx-2 rounded w-32 py-0.5 md:py-0 text-center purple-field"
                  placeholder="Masala Dosa"
                  type="text"
                  v-model="profileData.favorites.food"
                />
              </p>
            </div>

            <div class="binge-tv mb-3 md:mb-6">
              <p>I cheer for</p>
              <p>
                <input
                  class="mx-2 rounded w-40 py-0.5 md:py-0 text-center purple-field"
                  placeholder="Manchester City"
                  type="text"
                  v-model="profileData.favorites.cheer_for"
                />
              </p>
            </div>

            <div class="binge-tv mb-6">
              <p>Listening on Loop</p>
              <p>
                <input
                  class="mx-2 rounded w-36 py-0.5 md:py-0 text-center purple-field"
                  placeholder="Linkin park"
                  type="text"
                  v-model="profileData.favorites.song"
                />
              </p>
            </div>
          </CircleContainer>
        </div>

        <div
          class="skills mt-24 grid-2 lg:flex lg:justify-around md:flex-wrap lg:flex-nowrap items-baseline"
        >
          <div class="grid-item flex justify-center">
            <GIFContainer color="yellow" v-if="!profileData.skills.animation">
              <GIFAddButton color="yellow" @click="openModal('skills')" />
            </GIFContainer>

            <div v-else class="relative">
              <svg
                @click="openModal('skills')"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                />
              </svg>
              <img
                class="upload-box object-contain border p-3"
                :src="profileData.skills.animation"
                alt=""
              />
            </div>
          </div>

          <div class="skill-header">
            <span class="title font-Fancy yellow-text">Skills</span>
          </div>
        </div>

        <div class="illu justify-center md:justify-end my-5 flex">
          <img
            class="w-72 illustration"
            :src="require('@/assets/images/nha/pen.svg')"
            alt=""
          />
        </div>

        <div
          class="skill-content md:mt-24 flex justify-evenly flex-wrap lg:flex-nowrap"
        >
          <img
            class="w-48 self-start illustration hidden md:block"
            :src="require('@/assets/images/nha/guitar.svg')"
            alt=""
          />

          <CircleContainer color="yellow">
            <div class="mb-3 md:mb-6">
              <p>No one can beat me at</p>
              <input
                class="mx-2 rounded w-48 md:w-60 py-0.5 md:py-0 text-center yellow-field"
                placeholder="Any computer game ever"
                type="text"
                v-model="profileData.skills.unbeatable_at"
              />
            </div>

            <div class="mb-3 md:mb-6">
              <p class="mb-2">I also have mad skills in</p>
              <input
                class="mx-2 rounded w-48 md:w-60 py-0.5 md:py-0 text-center yellow-field"
                placeholder="Badminton and Table Tennis"
                type="text"
                v-model="profileData.skills.mad_skills_in"
              />
            </div>

            <div class="mb-3 md:mb-6">
              <p>I am good at playing</p>
              <input
                class="mx-2 rounded w-48 py-0.5 md:py-0 text-center yellow-field"
                placeholder="the guitar"
                type="text"
                v-model="profileData.skills.good_at_playing"
              />
            </div>

            <div class="prev-company">
              <p>and speaking</p>
              <p>
                <input
                  class="mx-2 rounded w-48 md:w-56 py-0.5 md:py-0 text-center yellow-field"
                  placeholder="Kannada, Tamil and Hindi"
                  type="text"
                  v-model="profileData.skills.languages"
                />
              </p>
            </div>
          </CircleContainer>

          <img
            class="w-48 md:w-60 lg:w-72 self-end illustration mt-8 md:mt-0"
            :src="require('@/assets/images/nha/running.svg')"
            alt=""
          />
        </div>

        <div class="inspiration-gif text-center md:text-left md:mt-24">
          <div
            class="inspiration-header mt-10 md:mt-24 text-center block md:hidden"
          >
            <span class="title font-Fancy green-text">Inspiration</span>
          </div>
          <div class="grid-item flex justify-center">
            <GIFContainer
              color="green"
              v-if="!profileData.inspiration.animation"
            >
              <GIFAddButton color="green" @click="openModal('inspiration')" />
            </GIFContainer>

            <div v-else class="relative">
              <svg
                @click="openModal('inspiration')"
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                />
              </svg>
              <img
                class="upload-box object-contain border p-3"
                :src="profileData.inspiration.animation"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          class="inspiration-header mt-10 md:mt-24 text-center hidden md:block"
        >
          <span class="title font-Fancy green-text">Inspiration</span>
        </div>

        <div
          class="inspiration-content my-2 md:my-24 flex justify-evenly flex-wrap lg:flex-nowrap relative"
        >
          <div class="block md:hidden absolute top-4 right-4">
            <img
              class="w-8 self-start illustration"
              :src="require('@/assets/images/nha/bulb.svg')"
              alt=""
            />
          </div>
          <img
            class="w-60 hidden md:block self-end illustration"
            :src="require('@/assets/images/nha/superman.svg')"
            alt=""
          />
          <CircleContainer color="green" class="mt-4 md:mt-0">
            <div class="mb-6">
              <p>My all time hero is</p>
              <input
                class="mx-2 rounded w-48 md:w-60 py-0.5 md:py-0 text-center green-field"
                placeholder="Superman a.k.a My Dad"
                type="text"
                v-model="profileData.inspiration.all_time_hero"
              />
            </div>

            <div class="mb-6">
              <p class="mb-2">The tech invention I can't live without is</p>
              <input
                class="mx-2 rounded w-48 py-0.5 md:py-0 text-center green-field"
                placeholder="My laptop"
                type="text"
                v-model="profileData.inspiration.cant_live_without_tech"
              />
            </div>

            <div class="mb-6">
              <p>I will be making friends and headlines at</p>
              <input
                class="mx-2 rounded w-48 py-0.5 md:py-0 text-center green-field"
                placeholder="Google"
                type="text"
                v-model="profileData.inspiration.friends_headlines_at"
              />
            </div>
          </CircleContainer>
          <img
            class="hidden md:block w-24 self-start illustration"
            :src="require('@/assets/images/nha/bulb.svg')"
            alt=""
          />
        </div>
        <div class="md:hidden flex justify-start">
          <img
            class="w-32 illustration"
            :src="require('@/assets/images/nha/superman.svg')"
            alt=""
          />
        </div>
      </div>
      <div class="action-buttons p-4 md:p-0 text-right">
        <button
          @click="preview()"
          class="bg-nColorBlue uppercase text-sm md:text-md px-8 md:px-16 py-2 md:py-3 rounded-lg font-semibold shadow mx-2 text-white"
        >
          preview
        </button>

        <ButtonInput
          @click.prevent="submit()"
          :buttonText="'submit'"
          :loading="loading"
          :buttonClass="'shadow duration-300 ease-out text-white px-8 md:px-16 py-1.5 md:py-3 bg-nButtonGreen rounded-lg hover:bg-green-500 hover:shadow-lg uppercase font-bold'"
          :loadingClass="'cursor-not-allowed shadow relative duration-300 ease-out text-white px-8 md:px-16 py-1.5 md:py-3 bg-nButtonGreen rounded-lg uppercase font-bold'"
        />
      </div>
    </div>
    <div class="completed text-white" v-else>
      <SuccessContent
        :link="profileLink"
        :target="'_self'"
        :successMsg="'Your profile was successfully created. Check out the below link.'"
      />
    </div>
    <GIFModal
      :modal="modal"
      @closeModal="modal.show = false"
      @selected="selectedGif($event)"
    />
    <NHAPreviewModal
      @closeModal="previewModal.show = false"
      :modal="previewModal"
      :option="'Option1'"
      :values="profileData"
      :defaults="experience.exp_data.default_animations"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import CircleContainer from "@/components/Experiences/NHA/Option1/CircleContainer.vue";
import GIFModal from "@/components/Experiences/NHA/Option1/GIFModal.vue";
import GIFAddButton from "@/components/Experiences/NHA/Option1/GIFAddButton.vue";
import GIFContainer from "@/components/Experiences/NHA/Option1/GIFContainer.vue";
import NHAPreviewModal from "@/components/Experiences/NHA/Preview.vue";
import SuccessContent from "@/components/Experiences/NHA/Success.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import { DatePicker } from "v-calendar";
import { format } from "date-fns";

export default {
  name: "ObkNHA",
  props: ["expDetails", "experience_link_id"],
  created() {
    this.experience = this.expDetails;
    this.profileData.user_name = this.expDetails.exp_data.name;
    if (this.expDetails.exp_data.birthday) {
      this.preDate = this.expDetails.exp_data.birthday;
      this.profileData.personal.dob = new Date(
        `${this.preDate.date} ${this.preDate.month}, ${this.preDate.year}`
      );

      if (this.experience.flags.complete) {
        this.profileLink = `${window.location.origin}/${this.$route.params.company}/${this.expDetails.exp_data.slug}`;
      }
    }

    this.dataFetched = true;
  },
  components: {
    GIFModal,
    CircleContainer,
    GIFAddButton,
    GIFContainer,
    NHAPreviewModal,
    DatePicker,
    SuccessContent,
    ButtonInput,
  },
  data() {
    return {
      experience: null,
      modal: {
        show: false,
        slug: null,
      },
      previewModal: {
        show: false,
      },
      gifGridLinks: {
        personal: [],
        interests: [],
        skills: [],
        favorites: [],
        inspiration: [],
      },
      gifLinks: [],
      profileLink: null,
      submitted: false,
      preDate: null,
      profileImage: null,
      loading: false,
      profileData: {
        user_name: null,
        birthday: null,
        splitBday: {
          day: null,
          month: null,
          year: null,
        },
        profile_picture: null,
        quote: null,
        personal: {
          animation: null,
          dob: null,
          based_out_of: null,
          designation: null,
          years_of_exp: null,
          last_job: null,
          family_info: null,
          social_media_link: null,
        },
        interests: {
          animation: null,
          wanted_to_be: null,
          learned_last_year: null,
        },
        favorites: {
          animation: null,
          book: null,
          tv_show: null,
          food: null,
          cheer_for: null,
          song: null,
        },
        skills: {
          animation: null,
          unbeatable_at: null,
          mad_skills_in: null,
          good_at_playing: null,
          languages: null,
        },
        inspiration: {
          animation: null,
          all_time_hero: null,
          cant_live_without_tech: null,
          friends_headlines_at: null,
        },
      },
    };
  },

  methods: {
    setProfileImage(e) {
      this.profileImage = e.target.files[0];
      let vue = this;
      const reader = new FileReader();
      reader.onload = function (e) {
        vue.$refs.profileImage.src = null;
        vue.$refs.profileImage.src = e.target.result;
        vue.profileData.profile_picture = e.target.result;
      };
      reader.readAsDataURL(this.profileImage);
    },
    selectedGif(e) {
      if (e.slug === "personal") {
        this.profileData.personal.animation = e.selected;
      } else if (e.slug === "skills") {
        this.profileData.skills.animation = e.selected;
      } else if (e.slug === "favorites") {
        this.profileData.favorites.animation = e.selected;
      } else if (e.slug === "inspiration") {
        this.profileData.inspiration.animation = e.selected;
      } else if (e.slug === "interests") {
        this.profileData.interests.animation = e.selected;
      }
    },
    populateGifGrid(slug) {
      ApiService.get(apiResource.obk.nha.animations, {
        animation_type: "animation",
        category: slug,
      }).then(({ data }) => {
        this.gifGridLinks[slug] = data.data.assets;
        this.gifLinks = data.data.assets;
      });
    },
    openModal(slug) {
      this.modal.show = true;
      this.modal.slug = slug;
    },
    preview() {
      this.profileData.birthday = {
        date: format(this.profileData.personal.dob, "do"),
        month: format(this.profileData.personal.dob, "LLLL"),
        year: format(this.profileData.personal.dob, "yyyy"),
      };
      this.previewModal.show = true;
      document.body.style.overflow = "hidden";
    },

    submit() {
      this.loading = true;
      const formdata = new FormData();
      const id =
        this.experience_link_id || this.$route.params.experience_link_id;

      if (this.profileData.personal.dob) {
        this.profileData.splitBday.day = format(
          this.profileData.personal.dob,
          "dd"
        );
        this.profileData.splitBday.month = format(
          this.profileData.personal.dob,
          "MM"
        );
        this.profileData.splitBday.year = format(
          this.profileData.personal.dob,
          "yyyy"
        );
        formdata.append("birthday", JSON.stringify(this.profileData.splitBday));
      }

      formdata.append("exp_link_id", id);
      formdata.append("profile_picture", this.profileImage);
      formdata.append("quote", this.profileData.quote);

      const personal = this.profileData.personal;
      const interests = this.profileData.interests;
      const favorites = this.profileData.favorites;
      const skills = this.profileData.skills;
      const inspiration = this.profileData.inspiration;

      formdata.append("personal", JSON.stringify(personal));
      formdata.append("favorites", JSON.stringify(favorites));
      formdata.append("skills", JSON.stringify(skills));
      formdata.append("interests", JSON.stringify(interests));
      formdata.append("inspiration", JSON.stringify(inspiration));

      ApiService.post(apiResource.obk.submitNHA, formdata)
        .then(() => {
          setTimeout(() => {
            window.location.reload();
            this.loading = false;
          }, 1500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getHeaderText() {
      return "";
    },
  },
};
</script>

<style scoped>
.obk-nha {
  background-repeat: no-repeat;
}

.bg-image {
  z-index: -1;
}

.grid-2 {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

.title {
  font-size: 5rem;
}

.green {
  background-color: #e4f7f4;
}

.yellow {
  background-color: #fff8de;
}

.purple {
  background-color: #e9ecff;
}
.pink {
  background-color: #fff0f5;
}

.solid-green {
  background-color: #11bca2;
}

.solid-pink {
  background-color: #ff5670;
}

.purple-btn {
  color: #7789e0;
}

.yellow-btn {
  color: #d8c27d;
}

.yellow-btn-bg {
  background-color: #d8c27d;
}

.pink-btn {
  color: #d89cae;
}

.yellow-text {
  color: #fdb400;
}

.green-text {
  color: #11bca2;
}

.pink-text {
  color: #ff5670;
}

.purple-text {
  color: #3d50e0;
}

.green-btn {
  color: #85c6ba;
}

.gray {
  background-color: rgba(245, 243, 243, 0.856);
}

.text-color {
  color: #777777;
}

.upload-box {
  width: 250px;
  height: 190px;
}

.round-box {
  width: 450px;
  height: 450px;
}

.social-link {
  box-shadow: 10px 10px 30px #11bca278;
}

.fav-link {
  box-shadow: 10px 10px 30px #ff566f8e;
}

input {
  transition: all 500ms ease-in-out;
}

.yellow-field:focus {
  outline: #fdb400 1px solid !important;
}

.green-field:focus {
  outline: #11bca2 1px solid !important;
}

.pink-field:focus {
  outline: #ff5670 1px solid !important;
}

.purple-field:focus {
  outline: #7789e0 1px solid !important;
}

.purple-field::placeholder,
.pink-field::placeholder,
.yellow-field::placeholder,
.green-field::placeholder {
  color: #1f1d1d41;
}

.illustration {
  background-color: white;
}

input[type="file"] {
  display: none;
}

@media (min-width: 768px) {
  .grid-2 {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }

  .title {
    background-color: white;
  }
}

@media (min-width: 1024px) {
  .obk-nha {
    padding: 0 2rem;
    background-image: url("~@/assets/images/nha/dottedBG.svg");
    background-size: cover;
    background-position: center;
  }
  .grid-2 {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .title {
    font-size: 6.5rem;
    line-height: 120px;
  }
  .upload-box {
    width: 300px;
    height: 240px;
  }
}

@media (min-width: 1280px) {
  .obk-nha {
    padding: 0 10rem;
  }

  .title {
    font-size: 5.5rem;
    line-height: 140px;
  }
  .upload-box {
    width: 360px;
    height: 270px;
  }
}

@media (min-width: 1400px) {
  .title {
    font-size: 7.5rem;
    line-height: 160px;
  }

  .upload-box {
    width: 400px;
    height: 270px;
  }
}

@media (min-width: 1600px) {
  .title {
    font-size: 8.5rem;
    line-height: 220px;
  }

  .upload-box {
    width: 450px;
    height: 300px;
  }
}
</style>
