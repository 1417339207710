<template>
  <div v-if="experience" class="container max-w-8xl mx-auto relative">
    <div v-if="!experience.flags.complete">
      <div
        class="md:px-4 pt-5 pb-4 mb-0 bg-white border border-nColorStrokeGray rounded-xl flex justify-center"
      >
        <div class="grid justify-items-center p-2 md:p-0">
          <div class="grid md:flex">
            <div class="md:w-3/5">
              <div class="h-full grid items-center justify-center">
                <div>
                  <span class="text-2xl md:text-2xl">Hi, I Am</span><br />
                  <div class="text-5xl md:text-8xl font-semibold">
                    {{ profileData.user_name }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="grid justify-items-center md:w-2/5 md:flex md:justify-end mt-2 md:mt-0"
            >
              <div class="yellow upload-box" v-if="!profileImage">
                <div
                  class="box-content h-full flex justify-center items-center text-color"
                >
                  <input
                    type="file"
                    name="profile-image"
                    id="profile-image"
                    @change="setProfileImage($event)"
                  />
                  <label
                    for="profile-image"
                    class="cursor-pointer grid justify-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-16 w-16 yellow-btn inline-block"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <p class="text-sm tracking-wide">
                      Click to add Profile Picture
                    </p>
                  </label>
                </div>
              </div>
              <div class="flex justify-center" v-else>
                <div class="object-contain bg-nLightYellow p-3 relative">
                  <input
                    type="file"
                    name="profile-image"
                    id="profile-image"
                    @change="setProfileImage($event)"
                  />
                  <label
                    for="profile-image"
                    class="cursor-pointer grid justify-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                      />
                    </svg>
                    <img
                      class="w-2/3 object-contain"
                      ref="profileImage"
                      :src="profileImage"
                      alt=""
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="md:w-5/6 bg-nColorMustard p-4 md:p-10 text-white grid justify-center"
          >
            <div class="pos mb-4">
              <p class="mb-2">
                I am a
                <span
                  class="inline-block mx-2 text-center text-xl font-semibold"
                >
                  <input
                    class="rounded w-30 md:w-44 px-1 yellow-field text-base text-black"
                    placeholder="Frontend Engineer"
                    type="text"
                    v-model="profileData.personal.designation"
                  />
                </span>
                based out of
                <span
                  class="inline-block mx-2 text-center text-xl font-semibold"
                >
                  <input
                    class="rounded w-30 md:w-44 px-1 yellow-field text-base text-black"
                    placeholder="Malaysia"
                    type="text"
                    v-model="profileData.personal.based_out_of"
                  />
                </span>
              </p>
            </div>
            <div class="exp">
              <p>
                I have
                <span
                  class="inline-block mx-2 text-center text-xl font-semibold"
                >
                  <input
                    class="rounded w-8 pl-2 yellow-field text-base text-black"
                    placeholder="99"
                    type="text"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    v-model="profileData.personal.years_of_exp"
                  />
                </span>
                years of experience and my last stint was at
                <span
                  class="inline-block text-center text-xl font-semibold mx-2"
                >
                  <input
                    class="rounded w-40 md:w-44 px-1 yellow-field text-base text-black"
                    placeholder="Morph Digital Solutions"
                    type="text"
                    v-model="profileData.personal.last_job"
                  />
                </span>
              </p>
            </div>
          </div>
          <div
            id="personal"
            class="grid justify-items-center md:flex w-full mt-10"
          >
            <div class="grid md:w-2/3">
              <div>
                <span class="text-3xl md:text-6xl font-semibold">
                  Personal
                </span>
                <hr class="w-11/12 float-right border-red-500" />
              </div>
              <div class="pt-4 md:pt-10 block md:hidden">
                <GIFContainer
                  color="green"
                  :needBorders="false"
                  v-if="!profileData.personal.animation"
                >
                  <GIFAddButton color="green" @click="openModal('personal')" />
                </GIFContainer>

                <div
                  v-else
                  class="upload-box border-4 border-nLightGreen p-3 relative overflow-hidden flex justify-center"
                >
                  <svg
                    @click="openModal('personal')"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                    />
                  </svg>
                  <img
                    class="h-full"
                    :src="profileData.personal.animation"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div
                  class="mt-4 md:mt-10 bg-nColorBlue p-10 text-white md:flex items-start"
                >
                  <p class="inline-block text-base md:mx-5">
                    My family consists of
                  </p>
                  <span class="font-semibold">
                    <textarea
                      v-model="profileData.personal.family_info"
                      class="rounded p-2 resize-none green-field text-black text-base"
                      name="familyDetails"
                      id="familyDetails"
                      cols="32"
                      rows="2"
                      placeholder="my dad, my wife Meena, 2 kids and Cherry our golden retreiver"
                    ></textarea>
                  </span>
                </div>
              </div>
            </div>
            <div class="pt-4 md:pt-10 hidden md:block">
              <GIFContainer
                color="green"
                :needBorders="false"
                v-if="!profileData.personal.animation"
              >
                <GIFAddButton color="green" @click="openModal('personal')" />
              </GIFContainer>

              <div v-else class="relative">
                <svg
                  @click="openModal('personal')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
                <img
                  class="upload-box object-contain border-4 border-nLightGreen p-3"
                  :src="profileData.personal.animation"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div id="interests" class="grid md:flex w-full mt-4 md:mt-10">
            <div class="md:pt-10 hidden md:block">
              <GIFContainer
                color="purple"
                :needBorders="false"
                v-if="!profileData.interests.animation"
              >
                <GIFAddButton color="purple" @click="openModal('interests')" />
              </GIFContainer>

              <div v-else class="relative">
                <svg
                  @click="openModal('interests')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
                <img
                  class="upload-box object-contain border-4 border-nLightPurple p-3"
                  :src="profileData.interests.animation"
                  alt=""
                />
              </div>
            </div>
            <div class="grid md:w-2/3 mt-4 md:mt-0">
              <div>
                <span
                  class="text-3xl md:text-6xl font-semibold flex justify-end"
                >
                  Interests
                </span>
                <hr class="w-11/12 float-left border-red-500" />
              </div>
              <div class="pt-4 md:pt-10 block md:hidden">
                <GIFContainer
                  color="purple"
                  :needBorders="false"
                  v-if="!profileData.interests.animation"
                >
                  <GIFAddButton
                    color="purple"
                    @click="openModal('interests')"
                  />
                </GIFContainer>

                <div
                  v-else
                  class="upload-box border-4 border-nLightPurple p-3 relative overflow-hidden flex justify-center"
                >
                  <svg
                    @click="openModal('interests')"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                    />
                  </svg>
                  <img
                    class="h-full"
                    :src="profileData.interests.animation"
                    alt=""
                  />
                </div>
              </div>
              <div class="mt-4 md:mt-0">
                <div class="bg-nColorMustard p-10 text-white flex items-start">
                  <div>
                    <div>
                      <p class="inline-block text-base md:mx-2">
                        When I was little, I wanted to be
                      </p>
                      <span class="font-semibold">
                        <input
                          class="rounded w-36 md:w-40 px-1 yellow-field text-base text-black"
                          placeholder="Cricketer"
                          type="text"
                          v-model="profileData.interests.wanted_to_be"
                        />
                      </span>
                    </div>
                    <div>
                      <p class="inline-block text-base md:mx-2 mt-4">
                        In the last year, I learned to
                      </p>
                      <span class="font-semibold">
                        <input
                          class="rounded w-36 md:w-40 px-1 yellow-field text-base text-black"
                          placeholder="How to cook"
                          type="text"
                          v-model="profileData.interests.learned_last_year"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="favorites"
            class="grid justify-items-center md:flex w-full mt-10"
          >
            <div class="grid md:w-2/3">
              <div>
                <span class="text-3xl md:text-6xl font-semibold">
                  Favorites
                </span>
                <hr class="w-11/12 float-right border-red-500" />
              </div>
              <div class="pt-4 md:pt-10 block md:hidden">
                <GIFContainer
                  color="yellow"
                  :needBorders="false"
                  v-if="!profileData.favorites.animation"
                >
                  <GIFAddButton
                    color="yellow"
                    @click="openModal('favorites')"
                  />
                </GIFContainer>

                <div
                  v-else
                  class="upload-box border-4 border-nLightYellow p-3 relative overflow-hidden flex justify-center"
                >
                  <svg
                    @click="openModal('favorites')"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                    />
                  </svg>
                  <img
                    class="h-full"
                    :src="profileData.favorites.animation"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div
                  class="mt-4 md:mt-10 bg-nColorBlue p-10 text-white grid gap-4"
                >
                  <div>
                    <p class="inline-block text-base md:mx-5">Book I love</p>
                    <span class="font-semibold ml-2 md:ml-0">
                      <input
                        class="rounded w-36 md:w-40 px-1 yellow-field text-base text-black"
                        placeholder="Harry potter"
                        type="text"
                        v-model="profileData.favorites.book"
                      />
                    </span>
                  </div>
                  <div>
                    <p class="inline-block text-base md:mx-5">
                      My comfort binge TV show
                    </p>
                    <span class="font-semibold">
                      <input
                        class="rounded w-36 md:w-40 px-1 yellow-field text-base text-black"
                        placeholder="Game of Thrones"
                        type="text"
                        v-model="profileData.favorites.tv_show"
                      />
                    </span>
                  </div>
                  <div>
                    <p class="inline-block text-base md:mx-5">
                      My favorite kinda food
                    </p>
                    <span class="font-semibold">
                      <input
                        class="rounded w-36 md:w-40 px-1 yellow-field text-base text-black"
                        placeholder="Masala Dosa"
                        type="text"
                        v-model="profileData.favorites.food"
                      />
                    </span>
                  </div>
                  <div>
                    <p class="inline-block text-base md:mx-5">I cheer for</p>
                    <span class="font-semibold ml-2 md:ml-0">
                      <input
                        class="rounded w-36 md:w-40 px-1 yellow-field text-base text-black"
                        placeholder="Manchester City"
                        type="text"
                        v-model="profileData.favorites.cheer_for"
                      />
                    </span>
                  </div>
                  <div>
                    <p class="inline-block text-base md:mx-5">
                      Listening on Loop
                    </p>
                    <span class="font-semibold">
                      <input
                        class="rounded w-36 md:w-40 px-1 yellow-field text-base text-black"
                        placeholder="Linkin park"
                        type="text"
                        v-model="profileData.favorites.song"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-4 md:pt-10 hidden md:block">
              <GIFContainer
                color="yellow"
                :needBorders="false"
                v-if="!profileData.favorites.animation"
              >
                <GIFAddButton color="yellow" @click="openModal('favorites')" />
              </GIFContainer>

              <div v-else class="relative">
                <svg
                  @click="openModal('favorites')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
                <img
                  class="upload-box object-contain border-4 border-nLightYellow p-3"
                  :src="profileData.favorites.animation"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div id="skills" class="grid md:flex w-full mt-4 md:mt-10">
            <div class="md:pt-10 hidden md:block">
              <GIFContainer
                color="pink"
                :needBorders="false"
                v-if="!profileData.skills.animation"
              >
                <GIFAddButton color="pink" @click="openModal('skills')" />
              </GIFContainer>

              <div v-else class="relative">
                <svg
                  @click="openModal('skills')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
                <img
                  class="upload-box object-contain border-4 border-nLightPink p-3"
                  :src="profileData.skills.animation"
                  alt=""
                />
              </div>
            </div>
            <div class="grid md:w-2/3 mt-4 md:mt-0">
              <div>
                <span
                  class="text-3xl md:text-6xl font-semibold flex justify-end"
                >
                  Skills
                </span>
                <hr class="w-11/12 float-left border-red-500" />
              </div>
              <div class="pt-4 md:pt-10 block md:hidden">
                <GIFContainer
                  color="pink"
                  :needBorders="false"
                  v-if="!profileData.skills.animation"
                >
                  <GIFAddButton color="pink" @click="openModal('skills')" />
                </GIFContainer>

                <div
                  v-else
                  class="upload-box border-4 border-nLightPink p-3 relative overflow-hidden flex justify-center"
                >
                  <svg
                    @click="openModal('skills')"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                    />
                  </svg>
                  <img
                    class="h-full"
                    :src="profileData.skills.animation"
                    alt=""
                  />
                </div>
              </div>
              <div class="mt-4">
                <div class="bg-nColorMustard p-10 text-white flex items-start">
                  <div>
                    <div>
                      <p class="inline-block text-base md:mx-2">
                        No one can beat me at
                      </p>
                      <span class="font-semibold">
                        <input
                          class="rounded w-52 md:w-44 px-1 yellow-field text-base text-black"
                          placeholder="Any computer game ever"
                          type="text"
                          v-model="profileData.skills.unbeatable_at"
                        />
                      </span>
                    </div>
                    <div>
                      <p class="inline-block text-base md:mx-2 mt-4">
                        I also have mad skills in
                      </p>
                      <span class="font-semibold">
                        <input
                          class="rounded w-52 md:w-48 px-1 yellow-field text-base text-black"
                          placeholder="Badminton and Table Tennis"
                          type="text"
                          v-model="profileData.skills.mad_skills_in"
                        />
                      </span>
                    </div>
                    <div>
                      <p class="inline-block text-base md:mx-2 mt-4">
                        I am good at playing
                      </p>
                      <span class="font-semibold">
                        <input
                          class="rounded w-52 md:w-40 px-1 yellow-field text-base text-black"
                          placeholder="the guitar"
                          type="text"
                          v-model="profileData.skills.good_at_playing"
                        />
                      </span>
                    </div>
                    <div>
                      <p class="inline-block text-base md:mx-2 mt-4">
                        and speaking
                      </p>
                      <span class="font-semibold">
                        <input
                          class="rounded w-52 md:w-44 px-1 yellow-field text-base text-black"
                          placeholder="Kannada, Tamil and Hindi"
                          type="text"
                          v-model="profileData.skills.languages"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="inspiration"
            class="grid justify-items-center md:flex w-full mt-10"
          >
            <div class="grid md:w-2/3">
              <div>
                <span class="text-3xl md:text-6xl font-semibold">
                  Inspiration
                </span>
                <hr class="w-11/12 float-right border-red-500" />
              </div>
              <div class="pt-4 md:pt-10 block md:hidden">
                <GIFContainer
                  color="green"
                  :needBorders="false"
                  v-if="!profileData.inspiration.animation"
                >
                  <GIFAddButton
                    color="green"
                    @click="openModal('inspiration')"
                  />
                </GIFContainer>

                <div
                  v-else
                  class="upload-box border-4 border-nLightGreen p-3 relative overflow-hidden flex justify-center"
                >
                  <svg
                    @click="openModal('inspiration')"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                    />
                  </svg>
                  <img
                    class="h-full"
                    :src="profileData.inspiration.animation"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div
                  class="mt-4 md:mt-10 bg-nColorBlue p-10 text-white grid gap-4"
                >
                  <div>
                    <p class="inline-block text-base md:mx-5">
                      My all time hero is
                    </p>
                    <span class="font-semibold">
                      <input
                        class="rounded w-52 md:w-40 px-1 yellow-field text-base text-black"
                        placeholder="Superman a.k.a My Dad"
                        type="text"
                        v-model="profileData.inspiration.all_time_hero"
                      />
                    </span>
                  </div>
                  <div>
                    <p class="inline-block text-base md:mx-5">
                      The tech invention I can't live without is
                    </p>
                    <span class="font-semibold">
                      <input
                        class="rounded w-52 md:w-40 px-1 yellow-field text-base text-black"
                        placeholder="My laptop"
                        type="text"
                        v-model="profileData.inspiration.cant_live_without_tech"
                      />
                    </span>
                  </div>
                  <div>
                    <p class="inline-block text-base md:mx-5">
                      I will be making friends and headlines at
                    </p>
                    <span class="font-semibold">
                      <input
                        class="rounded w-52 md:w-40 px-1 yellow-field text-base text-black"
                        placeholder="Google"
                        type="text"
                        v-model="profileData.inspiration.friends_headlines_at"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-4 md:pt-10 hidden md:block">
              <GIFContainer
                color="green"
                :needBorders="false"
                v-if="!profileData.inspiration.animation"
              >
                <GIFAddButton color="green" @click="openModal('inspiration')" />
              </GIFContainer>

              <div v-else class="relative">
                <svg
                  @click="openModal('inspiration')"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 text-white rounded-full p-1 yellow-btn-bg cursor-pointer absolute top-5 right-5 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
                <img
                  class="upload-box object-contain border-4 border-nLightGreen p-3"
                  :src="profileData.inspiration.animation"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action-buttons mt-5 mb-5 md:mb-0 text-right">
        <button
          @click="preview()"
          class="bg-nColorBlue uppercase text-sm md:text-md px-8 md:px-16 py-2 md:py-3 rounded-lg font-semibold shadow mx-2 text-white"
        >
          preview
        </button>

        <ButtonInput
          @click.prevent="submit()"
          :buttonText="'submit'"
          :loading="loading"
          :buttonClass="'shadow duration-300 ease-out text-white px-8 md:px-16 py-1.5 md:py-3 bg-nButtonGreen rounded-lg hover:bg-green-500 hover:shadow-lg uppercase font-bold'"
          :loadingClass="'cursor-not-allowed shadow relative duration-300 ease-out text-white px-8 md:px-16 py-1.5 md:py-3 bg-nButtonGreen rounded-lg uppercase font-bold'"
        />
      </div>
    </div>
    <div class="completed text-white" v-else>
      <SuccessContent
        :link="profileLink"
        :target="'_self'"
        :successMsg="'Your profile was successfully created. Check out the below link.'"
      />
    </div>
    <GIFModal
      :modal="modal"
      @closeModal="modal.show = false"
      @selected="selectedGif($event)"
    />
    <NHAPreviewModal
      @closeModal="previewModal.show = false"
      :modal="previewModal"
      :option="'Option2'"
      :values="profileData"
      :defaults="experience.exp_data.default_animations"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import GIFModal from "@/components/Experiences/NHA/Option2/GIFModal.vue";
import GIFAddButton from "@/components/Experiences/NHA/Option2/GIFAddButton.vue";
import GIFContainer from "@/components/Experiences/NHA/Option2/GIFContainer.vue";
import NHAPreviewModal from "@/components/Experiences/NHA/Preview.vue";
import SuccessContent from "@/components/Experiences/NHA/Success.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";

export default {
  name: "NHAPreviewOption2",
  props: ["expDetails", "experience_link_id"],
  created() {
    this.experience = this.expDetails;
    this.profileData.user_name = this.expDetails.exp_data.name;
    if (this.experience.flags.complete) {
      this.profileLink = `${window.location.origin}/${this.$route.params.company}/${this.expDetails.exp_data.slug}`;
    }

    this.dataFetched = true;
  },
  components: {
    GIFModal,
    GIFAddButton,
    GIFContainer,
    // DatePicker,
    NHAPreviewModal,
    ButtonInput,
    SuccessContent,
  },
  mounted() {},
  data() {
    return {
      experience: null,
      modal: {
        show: false,
        slug: null,
      },
      previewModal: {
        show: false,
      },
      gifGridLinks: {
        personal: [],
        interests: [],
        skills: [],
        favorites: [],
        inspiration: [],
      },
      gifLinks: [],
      profileLink: null,
      submitted: false,
      preDate: null,
      profileImage: null,
      loading: false,
      profileData: {
        user_name: null,
        splitBday: {
          day: null,
          month: null,
          year: null,
        },
        profile_picture: null,
        quote: null,
        personal: {
          animation: null,
          dob: null,
          based_out_of: null,
          designation: null,
          years_of_exp: null,
          last_job: null,
          family_info: null,
          social_media_link: null,
        },
        interests: {
          animation: null,
          wanted_to_be: null,
          learned_last_year: null,
        },
        favorites: {
          animation: null,
          book: null,
          tv_show: null,
          food: null,
          cheer_for: null,
          song: null,
        },
        skills: {
          animation: null,
          unbeatable_at: null,
          mad_skills_in: null,
          good_at_playing: null,
          languages: null,
        },
        inspiration: {
          animation: null,
          all_time_hero: null,
          cant_live_without_tech: null,
          friends_headlines_at: null,
        },
      },
    };
  },
  methods: {
    setProfileImage(e) {
      this.profileImage = e.target.files[0];
      let vue = this;
      const reader = new FileReader();
      reader.onload = function (e) {
        vue.$refs.profileImage.src = null;
        vue.$refs.profileImage.src = e.target.result;
        vue.profileData.profile_picture = e.target.result;
      };
      reader.readAsDataURL(this.profileImage);
    },
    selectedGif(e) {
      if (e.slug === "personal") {
        this.profileData.personal.animation = e.selected;
      } else if (e.slug === "skills") {
        this.profileData.skills.animation = e.selected;
      } else if (e.slug === "favorites") {
        this.profileData.favorites.animation = e.selected;
      } else if (e.slug === "inspiration") {
        this.profileData.inspiration.animation = e.selected;
      } else if (e.slug === "interests") {
        this.profileData.interests.animation = e.selected;
      }
    },
    populateGifGrid(slug) {
      ApiService.get(apiResource.obk.nha.animations, {
        animation_type: "animation",
        category: slug,
      }).then(({ data }) => {
        this.gifGridLinks[slug] = data.data.assets;
        this.gifLinks = data.data.assets;
      });
    },
    openModal(slug) {
      this.modal.show = true;
      this.modal.slug = slug;
    },
    preview() {
      this.previewModal.show = true;
      document.body.style.overflow = "hidden";
    },

    submit() {
      this.loading = true;
      const formdata = new FormData();
      const id =
        this.experience_link_id || this.$route.params.experience_link_id;

      formdata.append("exp_link_id", id);
      formdata.append("profile_picture", this.profileImage);
      formdata.append("quote", this.profileData.quote);

      const personal = this.profileData.personal;
      const interests = this.profileData.interests;
      const favorites = this.profileData.favorites;
      const skills = this.profileData.skills;
      const inspiration = this.profileData.inspiration;

      formdata.append("personal", JSON.stringify(personal));
      formdata.append("favorites", JSON.stringify(favorites));
      formdata.append("skills", JSON.stringify(skills));
      formdata.append("interests", JSON.stringify(interests));
      formdata.append("inspiration", JSON.stringify(inspiration));

      ApiService.post(apiResource.obk.submitNHA, formdata)
        .then(() => {
          setTimeout(() => {
            window.location.reload();
            this.loading = false;
          }, 1500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getHeaderText() {
      return "";
    },
  },
};
</script>

<style scoped>
.obk-nha {
  background-repeat: no-repeat;
}

.bg-image {
  z-index: -1;
}

.grid-2 {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

.title {
  font-size: 5rem;
}

.green {
  background-color: #e4f7f4;
}

.yellow {
  background-color: #fff8de;
}

.purple {
  background-color: #e9ecff;
}
.pink {
  background-color: #fff0f5;
}

.solid-green {
  background-color: #11bca2;
}

.solid-pink {
  background-color: #ff5670;
}

.purple-btn {
  color: #7789e0;
}

.yellow-btn {
  color: #d8c27d;
}

.yellow-btn-bg {
  background-color: #d8c27d;
}

.pink-btn {
  color: #d89cae;
}

.yellow-text {
  color: #fdb400;
}

.green-text {
  color: #11bca2;
}

.pink-text {
  color: #ff5670;
}

.purple-text {
  color: #3d50e0;
}

.green-btn {
  color: #85c6ba;
}

.gray {
  background-color: rgba(245, 243, 243, 0.856);
}

.text-color {
  color: #777777;
}

.upload-box {
  width: 100%;
  height: 190px;
}

.round-box {
  width: 450px;
  height: 450px;
}

.social-link {
  box-shadow: 10px 10px 30px #11bca278;
}

.fav-link {
  box-shadow: 10px 10px 30px #ff566f8e;
}

input {
  transition: all 500ms ease-in-out;
}

.yellow-field:focus {
  outline: #fdb400 1px solid !important;
}

.green-field:focus {
  outline: #11bca2 1px solid !important;
}

.pink-field:focus {
  outline: #ff5670 1px solid !important;
}

.purple-field:focus {
  outline: #7789e0 1px solid !important;
}

.purple-field::placeholder,
.pink-field::placeholder,
.yellow-field::placeholder,
.green-field::placeholder {
  color: #1f1d1d41;
}

.illustration {
  background-color: white;
}

input[type="file"] {
  display: none;
}

@media (min-width: 768px) {
  .grid-2 {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }

  .title {
    background-color: white;
  }
}

@media (min-width: 1024px) {
  .obk-nha {
    padding: 0 2rem;
    background-image: url("~@/assets/images/nha/dottedBG.svg");
    background-size: cover;
    background-position: center;
  }
  .grid-2 {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .title {
    font-size: 7rem;
    line-height: 120px;
  }
  .upload-box {
    width: 300px;
    height: 240px;
  }
}

@media (min-width: 1280px) {
  .obk-nha {
    padding: 0 10rem;
  }

  .title {
    font-size: 8rem;
    line-height: 140px;
  }
  .upload-box {
    width: 360px;
    height: 270px;
  }
}

@media (min-width: 1400px) {
  .title {
    font-size: 9rem;
    line-height: 160px;
  }

  .upload-box {
    width: 400px;
    height: 270px;
  }
}

@media (min-width: 1600px) {
  .title {
    font-size: 12rem;
    line-height: 220px;
  }

  .upload-box {
    width: 450px;
    height: 300px;
  }
}
</style>
