<template>
  <div id="content" class="flex-grow text-black" v-if="expDetails">
    <div v-if="expDetails && !expDetails.flags.complete">
      <div
        class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl hidden md:block mb-8"
      >
        <img
          :src="expDetails.details.qr.url"
          alt=""
          class="pt-10 relative h-96 left-1/2 transform -translate-x-1/2"
        />
        <p class="text-xl text-center mt-8 pb-8 text-black">
          This is a Mobile Only Experience.<br />Scan this QR code to experience
          this content on your AR compatible mobile devices.
        </p>
      </div>
      <div class="block md:hidden">
        <div class="scavenger-wrapper">
          <iframe
            id="scavenger_iframe"
            @load="loadedIframe()"
            class="w-full h-96"
            allow="camera"
            :src="iframeSrc"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
    <div class="leaderboard" v-else>
      <Leaderboard
        v-if="expDetails && expDetails.exp_data.leaderboard"
        :leaderboardContent="expDetails.exp_data.leaderboard"
        :showDuration="false"
      />
    </div>
  </div>
</template>

<script>
/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Leaderboard from "@/components/Experiences/Common/Leaderboard.vue";

export default {
  components: { Leaderboard },
  props: ["expDetails", "experience_link_id"],
  data() {
    return {
      iframeSrc: process.env.VUE_APP_EXP_SCAVENGER_IFRAME_URL,
      score: null,
    };
  },
  created() {
    const vue = this;
    window.onmessage = function(e) {
      try {
        const data = JSON.parse(e.data);
        if (data.type == "score_texperia") {
          vue.score = data.score;
          vue.submitScore();
        } else if (data.type == "enter_fullscreen") {
          vue.iframeFullScreen("enter");
        }
      } catch (error) {}
    };
  },
  mounted() {},
  methods: {
    loadedIframe() {
      const data = JSON.stringify({
        type: "token_texperia",
        token: localStorage.token,
      });

      document
        .getElementById("scavenger_iframe")
        .contentWindow.postMessage(data, "*");
    },

    submitScore() {
      ApiService.post(apiResource.obk.saveScavengerHuntScore, {
        exp_link_id: this.experience_link_id,
        score: this.score,
      })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(() => {});
    },
    iframeFullScreen(type) {
      let iframeElement = document.getElementById("scavenger_iframe");
      if (type == "enter") {
        iframeElement.classList.add("fullscreen");
        document.body.style.position = "fixed";
      } else {
        iframeElement.classList.remove("fullscreen");
      }
    },
  },
};
</script>
<style scoped lang="postcss">
.fullscreen {
  @apply absolute top-0 left-0 w-full h-full overflow-hidden z-50;
}
</style>
