<template>
  <div id="content" class="flex-grow text-black" v-if="expDetails">
    <div class="bg-white rounded-xl md:mb-10 md:p-4">
      <component
        :is="slugsConfig.nhaOptions[experience.details.option]"
        :expDetails="expDetails"
        :experience_link_id="experience_link_id"
      ></component>
    </div>
  </div>
</template>

<script>
import Option1 from "@/components/Experiences/NHA/Option1/Form.vue";
import Option2 from "@/components/Experiences/NHA/Option2/Form.vue";
import slugsConfig from "@/common/slugs";

export default {
  props: ["expDetails", "experience_link_id"],
  name: "EmployeeValueProposition",
  components: { Option1, Option2 },
  data() {
    return {
      experience: null,
      isReady: false,
      completed: false,
      slugsConfig,
    };
  },
  created() {
    this.experience = this.expDetails;
    this.isReady = this.expDetails.flags.is_ready;
    this.completed = this.expDetails.flags.complete;
  },
  mounted() {},
  updated() {},
  methods: {},
};
</script>

<style scoped></style>
