<template>
  <div v-if="modal.show" class="modal">
    <!-- Modal -->
    <div class="modal-wrapper fixed inset-0 overflow-y-auto text-gray-800 z-40">
      <div
        class="flex items-end justify-center min-h-screen text-center sm:block relative"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-80"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="inline-block align-middle h-screen" aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="mx-4 md:mx-0 inline-block bg-white rounded text-left overflow-hidden shadow-xl transform transition-all align-middle -translate-y-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white">
            <div>
              <div
                class="text-3xl font-semibold text-white bg-blue-900 px-2 py-4"
              >
                <div>
                  <h3 id="modal-headline">
                    <div class="w-full flex justify-between items-center gap-2">
                      <div class="text-2xl pl-2">How was your experience?</div>
                      <!-- <button
                        @click="closeModal()"
                        type="button"
                        class="inline-flex justify-end rounded-md pr-2 py-2 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-8 w-8 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button> -->
                    </div>
                  </h3>
                </div>
              </div>
              <div class="px-8 py-1 pb-3 bg-white text-base">
                <div
                  v-if="!submitted"
                  class="mx-8 mt-7 mb-6 md:mb-4 md:mt-6 md:mx-10"
                >
                  <form @submit.prevent="submit()">
                    <div class="like-exp">
                      <div class="w-full flex gap-10 justify-center">
                        <div class="w-1/3 text-center">
                          <div class="grid mb-2">
                            <input
                              type="radio"
                              required
                              value="happy"
                              class="opacity-0 pointer-events-none"
                              name="like"
                              id="like_happy"
                              v-model="like"
                            />
                            <label
                              for="like_happy"
                              @click.prevent="assignLike('happy')"
                            >
                              <img
                                id="happyIcon"
                                class="w-12 inline-block cursor-pointer happy"
                                :src="
                                  require('@/assets/images/feedback/happy.svg')
                                "
                                alt=""
                              />
                            </label>
                          </div>
                          <span id="title-happy" class="type-happy"
                            >Happy!</span
                          >
                        </div>
                        <div class="w-1/3 text-center">
                          <div class="grid mb-2">
                            <input
                              type="radio"
                              required
                              class="opacity-0 pointer-events-none"
                              value="neutral"
                              name="like"
                              id="like_neutral"
                              v-model="like"
                            />
                            <label
                              for="like_neutral"
                              @click.prevent="assignLike('neutral')"
                              ><img
                                id="neutralIcon"
                                class="w-12 inline-block cursor-pointer"
                                :src="
                                  require('@/assets/images/feedback/neutral.svg')
                                "
                                alt="neutral"
                            /></label>
                          </div>
                          <span id="title-neutral" class="type-neutral"
                            >Neutral</span
                          >
                        </div>
                        <div class="w-1/3 text-center">
                          <div class="grid mb-2">
                            <input
                              type="radio"
                              required
                              value="nothappy"
                              class="opacity-0 pointer-events-none"
                              name="like"
                              id="like_nothappy"
                              v-model="like"
                            />
                            <label
                              for="like_nothappy"
                              @click.prevent="assignLike('nothappy')"
                            >
                              <img
                                id="unhappyIcon"
                                class="w-12 inline-block cursor-pointer"
                                :src="
                                  require('@/assets/images/feedback/unhappy.svg')
                                "
                                alt="unhappy"
                              />
                            </label>
                          </div>
                          <span id="title-unhappy" class="type-unhappy"
                            >Unhappy!</span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div v-else>
                  <div class="text-xl font-semibold">
                    <div class="flex flex-row items-center">
                      <div class="text-lg text-black">
                        Thank you for your valuable feedback!
                      </div>
                      <lottie-animation
                        path="lotties/feedback-done.json"
                        :loop="true"
                        :autoPlay="true"
                        :speed="1"
                        :width="100"
                        :height="100"
                      />
                    </div>
                    <p class="text-gray-400 mt-2 text-sm mb-4">
                      You will be redirected shortly...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "ConfirmationModal",
  components: { LottieAnimation },
  props: {
    modal: {
      type: Object,
      required: true,
    },
    experience_link_id: { type: String, required: true },
  },
  data() {
    return {
      like: null,
      play: null,
      loading: false,
      submitted: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
    actionType(action) {
      this.$emit("closeModal", false);
      this.$emit(action);
    },
    assignLike(like) {
      this.like = like;
      this.submit();
    },
    assignPlay(play) {
      this.play = play;
    },
    submit() {
      this.loading = true;
      ApiService.post(apiResource.experience.feedback, {
        exp_link_id: this.experience_link_id,
        like: this.like,
      })
        .then(() => {
          this.submitted = true;
          setTimeout(() => this.$emit("closeModal", false), 1000);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.type-happy {
  color: #5c00b4;
}
.type-neutral,
.type-default {
  color: #000000;
}
.type-unhappy {
  color: #ff7846;
}
</style>
