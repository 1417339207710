<template>
  <div
    class="px-3 md:px-0 container max-w-8xl md:pt-10 mx-auto relative"
    v-if="experience"
  >
    <div v-if="experience" class="md:mt-5 mb-4 md:mb-0">
      <div v-if="experience">
        <div class="document-library">
          <Experience
            :documents="experience"
            :experience_link_id="experience_link_id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Experience from "@/components/Experiences/DocumentLibrary/Experience.vue";

export default {
  components: {
    Experience,
  },
  props: ["expDetails", "experience_link_id"],
  created() {
    this.experience = this.expDetails.exp_data;
  },
  data() {
    return {
      experience: null,
    };
  },
  methods: {},
};
</script>
<style scoped></style>
