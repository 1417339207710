<template>
  <div>
    <component
      :is="'Question' + question"
      :qData="qData"
      @storeProgress="storeProgress"
    >
    </component>
  </div>
</template>

<script>
import Question1 from "@/components/Experiences/CandidateExpSurvey/Questions/Question1.vue";
import Question2 from "@/components/Experiences/CandidateExpSurvey/Questions/Question2.vue";
import Question3 from "@/components/Experiences/CandidateExpSurvey/Questions/Question3.vue";
import Question4 from "@/components/Experiences/CandidateExpSurvey/Questions/Question4.vue";
import Question5 from "@/components/Experiences/CandidateExpSurvey/Questions/Question5.vue";
import Question6 from "@/components/Experiences/CandidateExpSurvey/Questions/Question6.vue";
import Question7 from "@/components/Experiences/CandidateExpSurvey/Questions/Question7.vue";
import Question8 from "@/components/Experiences/CandidateExpSurvey/Questions/Question8.vue";

export default {
  name: "CandidateExpSurveyQuestions",
  props: {
    question: {
      type: String,
      required: true,
    },
    qData: {
      required: true,
    },
  },
  components: {
    Question1,
    Question2,
    Question3,
    Question4,
    Question5,
    Question6,
    Question7,
    Question8,
  },
  data() {
    return {
      started: false,
    };
  },
  methods: {
    storeProgress(data) {
      this.$emit("storeProgress", data);
    },
  },
};
</script>

<style scoped></style>
