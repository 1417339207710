<template>
  <div class="bookshelf">
    <div class="covers flex justify-center flex-column space-x-2 md:space-x-12">
      <div
        class="document text-center rounded-lg p-2 my-2"
        v-for="book in books"
        :key="book.document_id"
      >
        <router-link
          :to="{
            name: 'DocumentViewer',
            params: {
              document_id: book.document_id,
              experience_link_id: experience_link_id,
            },
          }"
          class="text-gray-600 text-xs md:text-xl font-medium"
        >
          <div class="thumb rounded md:rounded-xl relative flex justify-center">
            <img
              class="h-20 md:w-40 md:h-auto rounded"
              :src="book.thumbnail.url"
              :alt="`Thumbnail for ${book.title}`"
              v-if="book.thumbnail.url"
            />
            <img
              class="h-20 md:w-40 md:h-auto rounded"
              :src="book.thumbnail"
              :alt="`Thumbnail for ${book.title}`"
              v-else
            />
          </div>
          <p class="mt-2">
            {{ book.title }}
          </p>
        </router-link>
      </div>
    </div>
    <div>
      <img
        class="shelf-img w-full mx-auto"
        :src="require('@/assets/images/documentlibrary/shelf_metal.png')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BookShelf",
  props: {
    books: {
      type: Array,
      required: true,
    },
    experience_link_id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
