<template>
  <div id="content" class="flex-grow text-black" v-if="experience">
    <div
      class="evp bg-white rounded-xl md:mb-10"
      v-if="experience.exp_data.categories"
    >
      <component
        :is="slugsConfig.evpOptions[experience.exp_data.option]"
        :evpData="experience.exp_data.categories"
      ></component>
    </div>
  </div>
</template>

<script>
import Radial from "@/components/Experiences/EVP/Radial.vue";
import Visual from "@/components/Experiences/EVP/Visual.vue";
import Bubbles from "@/components/Experiences/EVP/Bubbles.vue";
import slugsConfig from "@/common/slugs";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  props: ["expDetails", "experience_link_id"],
  name: "EmployeeValueProposition",
  components: { Radial, Visual, Bubbles },
  data() {
    return {
      experience: null,
      isReady: false,
      completed: false,
      slugsConfig,
    };
  },
  created() {
    this.experience = this.expDetails;
    this.isReady = this.expDetails.flags.is_ready;
    this.completed = this.expDetails.flags.complete;
  },
  mounted() {
    if (this.isReady) {
      ApiService.post(apiResource.obk.markExpAsComplete, {
        exp_link_id: this.experience_link_id,
      })
        .then(() => {})
        .catch(() => {});
    }
  },
  updated() {},
  methods: {},
};
</script>

<style scoped></style>
