<template>
  <div class="leaderboard text-black" v-if="leaderboardContent">
    <h3 class="text-2xl font-semibold">Leaderboard</h3>

    <ul class="mt-4">
      <li
        :class="{
          'bg-nButtonGreen text-white': item._id === profile.emp_id,
          'bg-gray-200 text-black': item._id !== profile.emp_id,
        }"
        class="rounded flex py-4 mb-2 items-center font-semibold"
        v-for="(item, index) in leaderboardContent"
        :key="item._id"
      >
        <div class="w-1/5">
          <img
            v-if="index == 0"
            class="inline-block ml-5 mr-4 w-6"
            :src="require('@/assets/images/trophy.svg')"
            alt=""
          />
          <span v-else class="inline-block ml-5 mr-5 w-5"></span>
        </div>
        <div class="w-2/5">
          <span class="inline-block">{{ item.name }}</span>
        </div>
        <div class="w-1/5">
          <span class="inline-block" v-if="item.total_questions">
            {{ item.score }}/{{ item.total_questions }}</span
          >
          <span class="inline-block" v-else> {{ item.score }}</span>
        </div>
        <div class="w-1/5">
          <template v-if="showDuration">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block md:ml-10 mr-2 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span class="inline-block">{{ item.total_duration }}s</span>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    showDuration: {
      type: Boolean,
      default: () => false,
    },
    leaderboardContent: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["profile"]),
  },
};
</script>

<style scoped></style>
