<template>
  <div id="content" class="text-black mb-8 relative" v-if="expDetails">
    <div class="threetruths-content">
      <div
        class="statement-card text-bgcolor bg-nColorFillGray border border-nColorStrokeGray rounded-xl m-4 md:m-0"
        v-for="statement in statements"
        :key="statement.statement_id"
      >
        <div
          class="statistics flex flex-wrap"
          v-if="
            statistics[statement.statement_id] && statement.has_played.status
          "
        >
          <div
            class="flex flex-col md:flex-row w-full bg-blue-900 text-white px-8 py-6 rounded-t-lg"
          >
            <div class="w-full">
              <h3 class="text-lg md:text-3xl font-bold mb-2">
                {{ statement.title }}
              </h3>
              <p class="mb-2 text-sm md:text-xl">{{ statement.description }}</p>
            </div>
            <div
              class="w-full grid place-content-start md:place-content-end text-xl md:mb-5 mt-5 md:mt-0"
            >
              <p>
                You answered
                <span class="font-semibold text-lg md:text-2xl"
                  >"{{
                    statement.has_played.answer ||
                      answerLabels[statement.statement_id]
                  }}"</span
                >
              </p>
            </div>
          </div>
          <div class="w-full flex justify-center px-8 py-6">
            <Experience
              :chartData="chartFactory(statistics[statement.statement_id])"
            />
          </div>
        </div>

        <div class="form" v-else>
          <form
            action=""
            @submit.prevent="onSubmitAnswer(statement.statement_id)"
          >
            <div class="statements">
              <div
                class="statement-header bg-blue-900 text-white px-8 py-6 rounded-t-lg"
              >
                <h3 class="text-lg md:text-3xl font-bold mb-2">
                  {{ statement.title }}
                </h3>
                <p class="mb-2 font-medium text-sm md:text-xl">
                  {{ statement.description }}
                </p>
              </div>
              <div
                class="statements-content flex flex-wrap p-4 md:p-8 md:pl-16 border-b"
              >
                <div
                  class="statement w-full md:w-1/2 mb-5 md:mb-0 flex justify-start items-start"
                >
                  <label
                    :class="{
                      'bg-nColorBlue text-white font-bold pl-6':
                        answers[statement.statement_id] == 'statement1',
                      'bg-white text-black font-medium pl-6 hover:bg-nColorBlue hover:text-white hover:font-bold':
                        answers[statement.statement_id] != 'statement1',
                    }"
                    class="w-full inline-block p-3 border border-nColorBlue rounded-3xl transition-all duration-300 ease-in-out cursor-pointer"
                    :for="'statement1' + statement.statement_id"
                    >{{ statement.statement1 }}</label
                  >
                  <input
                    required
                    class="w-1/12 h-full shadow opacity-0 pointer-events-none"
                    type="radio"
                    :name="statement.statement_id"
                    :id="'statement1' + statement.statement_id"
                    value="statement1"
                    v-model="answers[statement.statement_id]"
                  />
                </div>
                <div
                  class="statement w-full md:w-1/2 mb-5 flex justify-start items-center"
                >
                  <label
                    :class="{
                      'bg-nColorBlue text-white font-bold pl-6':
                        answers[statement.statement_id] == 'statement2',
                      'bg-white text-black font-medium pl-6 hover:bg-nColorBlue hover:text-white hover:font-bold':
                        answers[statement.statement_id] != 'statement2',
                    }"
                    class="w-full  inline-block p-3  border border-nColorBlue rounded-3xl transition-all duration-300 ease-in-out cursor-pointer "
                    :for="'statement2' + statement.statement_id"
                    >{{ statement.statement2 }}</label
                  >
                  <input
                    required
                    class="w-1/12 h-full shadow opacity-0 pointer-events-none"
                    type="radio"
                    :name="statement.statement_id"
                    :id="'statement2' + statement.statement_id"
                    value="statement2"
                    v-model="answers[statement.statement_id]"
                  />
                </div>
                <div
                  class="statement w-full md:w-1/2 mb-5 md:mb-0 flex justify-start items-center"
                >
                  <label
                    :class="{
                      'bg-nColorBlue text-white font-bold pl-6':
                        answers[statement.statement_id] == 'statement3',
                      'bg-white text-black font-medium pl-6 hover:bg-nColorBlue hover:text-white hover:font-bold':
                        answers[statement.statement_id] != 'statement3',
                    }"
                    class="w-full  inline-block p-3  border border-nColorBlue rounded-3xl transition-all duration-300 ease-in-out cursor-pointer"
                    :for="'statement3' + statement.statement_id"
                    >{{ statement.statement3 }}</label
                  >
                  <input
                    required
                    class="w-1/12 h-full shadow opacity-0 pointer-events-none"
                    type="radio"
                    :name="statement.statement_id"
                    :id="'statement3' + statement.statement_id"
                    value="statement3"
                    v-model="answers[statement.statement_id]"
                  />
                </div>
                <div
                  class="statement w-full md:w-1/2 flex justify-start items-center"
                >
                  <label
                    required
                    :class="{
                      'bg-nColorBlue text-white font-bold pl-6':
                        answers[statement.statement_id] == 'statement4',
                      'bg-white text-black font-medium pl-6 hover:bg-nColorBlue hover:text-white':
                        answers[statement.statement_id] != 'statement4',
                    }"
                    class="w-full inline-block p-3 border border-nColorBlue rounded-3xl transition-all duration-300 ease-in-out cursor-pointer"
                    :for="'statement4' + statement.statement_id"
                    >{{ statement.statement4 }}</label
                  >
                  <input
                    class="w-1/12 h-full shadow opacity-0 pointer-events-none"
                    type="radio"
                    :name="statement.statement_id"
                    :id="'statement4' + statement.statement_id"
                    value="statement4"
                    v-model="answers[statement.statement_id]"
                  />
                </div>
              </div>
            </div>

            <div class="w-11/12 text-right mx-6 py-4">
              <button
                class="bg-nButtonGreen text-white py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold px-6 md:px-12 shadow mr-4 md:mr-0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Experience from "@/components/Experiences/TTAL/Experience.vue";

export default {
  name: "ObkThreeTruths",
  components: { Experience },
  props: ["expDetails", "experience_link_id"],
  data() {
    return {
      experience: null,
      dataFetched: false,
      showOptions: {},
      showStats: {},
      answers: {},
      answerLabels: {},
      statistics: {},
      statements: [],
      showFeedback: false,
    };
  },
  created() {
    this.dataFetched = true;
    this.experience = this.expDetails;

    this.statements = this.expDetails.exp_data.statements;
    this.statements.forEach((statement) => {
      this.statistics[statement.statement_id] = statement.statistics;
    });
  },
  methods: {
    onSubmitAnswer(id) {
      ApiService.post(apiResource.obk.addTTALAnswer, {
        exp_link_id: this.experience_link_id,
        statement_id: id,
        answer: this.answers[id],
      })
        .then((result) => {
          this.answerLabels[id] = result.data.data.answer.statement;
          this.statistics[id] = result.data.data.statistics;
          this.statements[
            this.statements.findIndex(
              (statement) => statement.statement_id == id
            )
          ].has_played.status = true;
          this.showFeedback = result.data.data.flags.show_feedback;
        })
        .catch(() => {});
    },
    chartFactory(stats) {
      return {
        labels: stats.map((option) => option.label),
        data: stats.map((option) => option.percentage),
      };
    },
  },
};
</script>

<style scoped></style>
