<template>
  <div
    class="relative left-1/2 transform -translate-x-1/2 bg-nColorFillGray border border-nColorStrokeGray rounded-xl"
  >
    <lottie-animation
      class="hidden md:block"
      path="lotties/not-ready.json"
      :loop="true"
      :autoPlay="true"
      :speed="1"
      :width="512"
      :height="512"
    />
    <lottie-animation
      class="block md:hidden"
      path="lotties/not-ready.json"
      :loop="true"
      :autoPlay="true"
      :speed="1"
      :width="256"
      :height="256"
    />
    <p class="pb-8 text-xl text-center text-black">
      Your experience is being generated. Please check back in sometime.
    </p>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: { LottieAnimation },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
