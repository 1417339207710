<template>
  <div v-if="modal.show" class="modal">
    <!-- Modal -->
    <div class="modal-wrapper fixed inset-0 overflow-y-auto">
      <div
        class="flex items-end md:justify-center min-h-screen md:pt-4 px-3 pl-4 md:pl-0 md:px-4 pb-10 md:pb-20 text-center md:block md:p-0"
      >
        <div
          @click="closeModal()"
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden md:inline-block md:align-middle md:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform
                 transition-all md:my-8 md:align-middle md:max-w-lg w-11/12 md:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white">
            <div class="grid">
              <div
                class="flex justify-between text-lg md:text-3xl font-semibold text-white bg-nDarkBlue px-4 md:px-8 py-4"
              >
                <div>
                  <h3 id="modal-headline">
                    Select Animation/GIF
                  </h3>
                </div>
                <div
                  class="flex items-center cursor-pointer"
                  @click="closeModal()"
                >
                  <svg class="svg-icon w-4 h-4" viewBox="0 0 20 20">
                    <path
                      fill="#FFFFFF"
                      d="M11.469,10l7.08-7.08c0.406-0.406,0.406-1.064,0-1.469c-0.406-0.406-1.063-0.406-1.469,0L10,8.53l-7.081-7.08
                      c-0.406-0.406-1.064-0.406-1.469,0c-0.406,0.406-0.406,1.063,0,1.469L8.531,10L1.45,17.081c-0.406,0.406-0.406,1.064,0,1.469
                      c0.203,0.203,0.469,0.304,0.735,0.304c0.266,0,0.531-0.101,0.735-0.304L10,11.469l7.08,7.081c0.203,0.203,0.469,0.304,0.735,0.304
                      c0.267,0,0.532-0.101,0.735-0.304c0.406-0.406,0.406-1.064,0-1.469L11.469,10z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="bg-white">
                {{ searchterm }}
                <div class="flex text-gray-500">
                  <button
                    :class="{
                      'bg-nColorBlue border-b border-nColorBlue text-white':
                        option == 'animation',
                    }"
                    class="px-4 py-2 md:p-4 w-1/2 border-b font-semibold uppercase"
                    @click="option = 'animation'"
                  >
                    Animation
                  </button>
                  <button
                    :class="{
                      'bg-nColorBlue border-b border-nColorBlue text-white':
                        option == 'giphy',
                    }"
                    class="px-4 py-2 md:p-4 border-b w-1/2 font-semibold uppercase"
                    @click="option = 'giphy'"
                  >
                    Gif
                  </button>
                </div>

                <div class="tab-contents text-bgcolor">
                  <div
                    class="tab-animation"
                    v-if="option === 'animation' && links"
                  >
                    <div
                      v-if="loading"
                      class="flex justify-center items-center"
                    >
                      <svg
                        class="animate-spin w-96 inline text-nColorBlue"
                        version="1.1"
                        id="loader-1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 50 50"
                        style="enable-background:new 0 0 50 50;"
                        xml:space="preserve"
                      >
                        <path
                          fill="currentColor"
                          d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
                        ></path>
                      </svg>
                    </div>
                    <GIFGrid
                      v-else
                      :links="links"
                      @selected="gridSelection($event)"
                    />
                  </div>
                  <div class="tab-gif" v-if="option === 'giphy'">
                    <div class="search-input">
                      <form role="search" @submit.prevent="searchAnimation()">
                        <div class="relative text-gray-600 px-4 pt-4">
                          <input
                            class="border border-blue-600 bg-white w-full h-12 px-3 pr-16 rounded-lg text-ml focus:outline-none"
                            type="search"
                            name=""
                            id=""
                            placeholder="Search"
                            v-model="searchterm"
                          />
                          <button
                            type="submit"
                            class="absolute right-4 top-4 pt-4 pr-4"
                          >
                            <svg
                              class="text-blue-600 h-4 w-4 fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              version="1.1"
                              id="Capa_1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 56.966 56.966"
                              style="enable-background:new 0 0 56.966 56.966;"
                              xml:space="preserve"
                              width="512px"
                              height="512px"
                            >
                              <path
                                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                              />
                            </svg>
                          </button>
                        </div>
                      </form>
                    </div>
                    <GIFGrid
                      v-if="searchResults"
                      :links="searchResults"
                      @selected="gridSelection($event)"
                    />
                    <GIFGrid
                      v-else-if="links"
                      :links="links"
                      @selected="gridSelection($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit text-right sticky bottom-0 bg-white p-2 border-t">
            <button
              @click="selectGif()"
              class="px-8 md:px-16 uppercase font-bold bg-nButtonGreen text-white py-1.5 md:py-3 rounded md:rounded-lg"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import GIFGrid from "@/components/Experiences/NHA/Option1/GIFGrid.vue";
import { apiResource } from "@/common/app.config";

export default {
  name: "GIFModal",
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  components: { GIFGrid },
  data() {
    return {
      option: "animation",
      searchterm: null,
      selected: null,
      searchResults: null,
      links: null,
      loading: true,
    };
  },

  watch: {
    "modal.show": function(value) {
      if (value) {
        this.loading = true;
        this.links = null;
        ApiService.get(apiResource.experience.getNHAAnimations, {
          animation_type: this.option,
          category: this.modal.slug,
        }).then(({ data }) => {
          this.links = data.data.assets;
          this.loading = false;
        });
      }
    },

    option: function(value) {
      this.links = null;
      ApiService.get(apiResource.experience.getNHAAnimations, {
        animation_type: value,
        category: this.modal.slug,
      }).then(({ data }) => {
        this.links = data.data.assets;
      });
    },

    searchterm: function(value) {
      if (value === "") {
        this.searchResults = null;
        ApiService.get(apiResource.experience.getNHAAnimations, {
          animation_type: this.option,
          category: this.modal.slug,
        }).then(({ data }) => {
          this.links = data.data.assets;
          this.loading = false;
        });
      }
    },
  },

  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
    actionType(action) {
      this.$emit("closeModal", false);

      this.$emit(action);
    },
    gridSelection(e) {
      this.selected = e;
    },

    selectGif() {
      this.$emit("selected", {
        selected: this.selected,
        slug: this.modal.slug,
      });
      this.closeModal();
    },

    searchAnimation() {
      this.searchResults = null;
      this.links = null;
      ApiService.get(apiResource.experience.searchGIPHY, {
        search: this.searchterm,
      }).then(({ data }) => {
        this.searchResults = data.data.assets;
      });
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  z-index: 99999;
}
</style>
