<template>
  <div class="flex justify-center  flex-col bg-survey-bg question pb-8">
    <p
      class="font-bold text-2xl pt-10 px-4 text-survey-black text-center"
      v-html="current.title"
    ></p>

    <div class="flex justify-center lg:py-8">
      <img id="image" alt="image" class="image" />
    </div>

    <div
      class="question-wizard pt-10"
      id="question-wizard"
      v-if="current.type == 'multiple-choice'"
    >
      <ul class="question-tabs" id="question-tabs">
        <li
          v-for="index in 5"
          :key="index"
          @click="changeImage(index)"
          :class="{ selected: index === currentIndex }"
        ></li>
      </ul>
    </div>
    <div class="flex items-center justify-center" v-else>
      <button
        class="btn text-white secondaryBg font-extrabold  hover:bg-teal-900 text-3xl rounded-full focus:outline-none"
        @click="decrement"
      >
        -
      </button>
      <span class="m-5  text-5xl border-4 text border-survey-black" id="value"
        >0</span
      >

      <button
        class="btn text-white secondaryBg font-extrabold hover:bg-teal-900 text-3xl rounded-full focus:outline-none"
        @click="increment"
      >
        +
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  components: {},
  props: ["current"],
  data() {
    return {
      value: 0,
      currentIndex: 0,
    };
  },
  mounted() {
    this.changeImage(1);
  },
  methods: {
    resetWizard() {
      this.currentIndex = 1;
    },
    changeImage(index) {
      document.querySelector(
        "#image"
      ).src = require("@/assets/images/managernps/question" +
        this.current.id +
        "/q" +
        index +
        ".png");
      this.currentIndex = index;
      this.$emit("storeProgress", index);
    },
    decrement() {
      if (this.value > 1) {
        this.value -= 1;
        document.querySelector("#value").innerHTML = this.value;
        this.changeImage(this.value);
      }
    },
    increment() {
      if (this.value < this.current.count) {
        this.value += 1;
        document.querySelector("#value").innerHTML = this.value;
        this.changeImage(this.value);
      }
    },
  },
};
</script>

<style>
body {
  background: #f5f5f5 !important;
}
.image {
  max-width: 75% !important;
}
.nav {
  position: fixed;
  top: 0;
  width: 100%;
}
#wizard .step {
  height: 40px;
  width: 40px;
}
.question-wizard {
  width: 100%;
  text-align: center;
}

.question-tabs {
  counter-reset: step;
}
.question-tabs li:first-child:nth-last-child(5),
.question-tabs li:first-child:nth-last-child(5) ~ li {
  width: 10%;
}

.question-tabs li {
  list-style: none;
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
}

a {
  color: #000;
}

.question-tabs li a {
  text-decoration: none;
}

.question-tabs li:before {
  content: counter(step);
  counter-increment: step;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background: #231f20;
  border: 1px solid #231f20;
  color: #fff;
  position: relative;
  z-index: 3;
  font-size: 2rem;
}
.question-tabs li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #ddd;
  top: 28px;
  left: -47%;
  z-index: 1;
}
.selected:before {
  background: #ffffff !important;
  color: #231f20 !important;
}

.question-tabs li:first-child:after {
  content: none;
}

.question-tabs li,
.question-tabs li a {
  color: #231f20;
}

.question-tabs li a:hover {
  font-weight: bold;
}

.question-tabs li + li:after {
  background-color: #231f20;
}
.btn {
  height: 60px;
  width: 60px;
}
.text {
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 60px !important;
  border-radius: 6px;
}
.secondaryBg {
  background: #11bca2;
}
@media (max-width: 768px) {
  .question-tabs li:first-child:nth-last-child(5),
  .question-tabs li:first-child:nth-last-child(5) ~ li {
    width: 14%;
  }
  .question-tabs li:before {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1rem;
  }
  .question-tabs li:after {
    top: 13px;
  }
}
</style>
