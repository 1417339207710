<template>
  <div id="content" class="flex-grow text-black" v-if="expDetails">
    <div class="welcome-video bg-white rounded-xl">
      <div v-if="isReady">
        <VideoContainer
          :experience_link_id="experience_link_id"
          :videoLink="experience.url"
        />
      </div>
      <div v-else>
        <NotReady
          message="Your experience is being generated. Please check back in sometime."
        />
      </div>
    </div>
  </div>
</template>

<script>
import VideoContainer from "@/components/Experiences/VideoExperiences/VideoContainer.vue";
import NotReady from "@/components/Experiences/Common/NotReady.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  props: ["expDetails", "experience_link_id"],
  name: "InductionVideo",
  components: {
    VideoContainer,
    NotReady,
  },
  data() {
    return {
      experience: null,
      isReady: false,
      completed: false,
    };
  },
  created() {
    this.experience = this.expDetails.details;
    this.isReady = this.expDetails.flags.is_ready;
    this.completed = this.expDetails.flags.complete;
  },
  mounted() {
    if (this.isReady) {
      ApiService.post(apiResource.obk.markExpAsComplete, {
        exp_link_id: this.experience_link_id,
      })
        .then(() => {})
        .catch(() => {});
    }
  },
  updated() {},
  methods: {},
};
</script>

<style scoped></style>
