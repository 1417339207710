<template>
  <div class="gif-grid p-2 overflow-scroll">
    <div class="grid-layout h-96 grid grid-cols-3 gap-1">
      <div class="grid-item" v-for="item in links" :key="item">
        <input
          class="absolute opacity-0 pointer-events-none"
          type="radio"
          :name="name"
          :id="item"
          :value="item"
          v-model="selected"
          @change="changeSelected()"
        />
        <label :for="item">
          <img
            :class="{
              'border-btnGreen': selected === item,
              'border-white': selected !== item,
            }"
            class="w-32 md:w-full cursor-pointer border-4 transition-all duration-300 ease-in-out "
            :src="item"
            alt=""
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GIFGrid",
  props: {
    links: {
      required: true,
      type: Array,
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    changeSelected() {
      this.$emit("selected", this.selected);
    },
  },
};
</script>

<style scoped></style>
