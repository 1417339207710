<template>
  <div class="gtm-movie">
    <div class="gtm-emote-container bg-nColorBlue mt-5 rounded md:rounded-xl">
      <div class="emotes text-center">
        <h1 class="text-3xl md:text-8xl py-4 md:py-8">
          {{ movie.emotes }}
        </h1>
      </div>
    </div>
    <div
      class="gtm-options mt-2 md:mt-5 bg-nColorFillGray border border-nColorStrokeGray rounded-xl"
    >
      <div
        class="options-select flex flex-wrap py-2 text-center text-black justify-center"
      >
        <div
          class="word flex flex-wrap justify-start my-2 md:my-5 mx-2 md:mx-10"
          v-for="(word, i) in movie.name"
          :key="i"
        >
          <input
            v-for="(letter, j) in word"
            :key="j"
            :class="{
              correct: entry[i][j] === String.fromCharCode(letter),
              wrong: entry[i][j] && entry[i][j] !== String.fromCharCode(letter),
            }"
            @keydown.space.prevent=""
            maxlength="1"
            class="bg-white rounded md:rounded-lg text-black mb-1 md:mb-0 p-1.5 md:p-3 shadow border border-blue-600 text-sm md:text-base
                   focus:outline-none focus:border-blue-500 text-center font-semibold w-8 md:w-10 mx-1 uppercase"
            type="text"
            @input="
              assignLetter(
                i,
                j,
                movie.movie_id,
                $event,
                movie.name.length,
                word.length
              )
            "
            @keydown.delete="
              focusBack(
                i,
                j,
                movie.movie_id,
                $event,
                movie.name.length,
                word.length
              )
            "
            :ref="movie.movie_id + '_' + i + '_' + j"
          />
        </div>
      </div>
    </div>
    <div class="buttons mt-2 md:mt-5 mb-4 md:mb-0 text-right">
      <button
        @click="skipMovie()"
        class="bg-nButtonGreen font-semibold tracking-wider px-5 md:px-10 rounded-lg text-white
               py-2 md:py-4 mr-2 md:mr-4 text-sm md:text-xl hover:bg-green-800"
      >
        Pass
      </button>
      <button
        @click="endGTM()"
        class="border-2 border-red-500 font-bold text-red-500 tracking-wider px-5 md:px-10
               rounded-lg py-2 md:py-4 text-sm md:text-xl hover:bg-red-500 hover:text-white"
      >
        End
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "GTMMovie",
  props: {
    movie: {
      type: Object,
      required: true,
    },
    userInputInit: {
      type: Array,
      required: true,
    },
    solution: {
      type: String,
    },
  },
  components: {},
  created() {
    this.entry = [...this.userInputInit];
  },
  mounted() {
    this.$refs[`${this.movie.movie_id}_${0}_${0}`][0].focus();
  },
  data() {
    return {
      entry: null,
      answer: {
        movie_id: this.movie.movie_id,
        guess_name: null,
        guess_correct: null,
        has_skipped: null,
        time_taken: null,
      },
    };
  },
  methods: {
    assignLetter(i, j, id, e, iLength, jLength) {
      this.entry[i][j] = e.target.value.toUpperCase();

      if (
        this.entry
          .join(",")
          .split(",")
          .join("") == this.solution
      ) {
        this.answerCorrect();
      }

      if (e.target.value >= e.target.attributes.maxLength.value) {
        if (j < jLength - 1) {
          this.$refs[`${id}_${i}_${j + 1}`][0].focus();
        } else if (i < iLength - 1) {
          this.$refs[`${id}_${i + 1}_${0}`][0].focus();
        } else {
          return;
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    focusBack(i, j, id, e, iLength, jLength) {
      if (e.target.value == "") {
        if (j > 0) {
          this.$refs[`${id}_${i}_${j - 1}`][0].focus();
        } else if (i > 0) {
          this.$refs[
            `${id}_${i - 1}_${this.userInputInit[i - 1].length - 1}`
          ][0].focus();
        } else {
          return;
        }
      }
    },

    answerCorrect() {
      this.answer.guess_correct = 1;
      this.answer.has_skipped = 0;
      this.answer.guess_name = this.entry
        .map((wordArr) => wordArr.join(""))
        .join(" ");
      this.$emit("correct", this.answer);
    },
    skipMovie() {
      this.answer.guess_correct = "0";
      this.answer.has_skipped = "1";
      this.answer.guess_name = "";
      this.$emit("skip", this.answer);
    },
    counterEnded() {
      // this.answer.guess_correct = 0;
      this.endGTM();
    },
    endGTM() {
      this.answer.guess_correct = "0";
      this.answer.has_skipped = "1";
      this.answer.guess_name = "";
      this.$emit("endGTM", this.answer);
    },
  },
};
</script>

<style scoped>
.correct {
  border: 2px solid rgb(28, 156, 107);
}
.wrong {
  border: 2px solid rgb(214, 32, 32);
}
</style>
