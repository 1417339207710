<template>
  <div class="upload-box-container">
    <div
      :class="{
        yellow: color === 'yellow',
        purple: color === 'purple',
        green: color === 'green',
        pink: color === 'pink',
      }"
      class="upload-box"
    >
      <div
        class="box-content h-full flex justify-center items-center text-color"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GIFContainer",
  props: {
    color: {
      required: true,
    },
  },
};
</script>

<style scoped>
.green {
  background-color: #e4f7f4;
}

.yellow {
  background-color: #fff8de;
}

.purple {
  background-color: #e9ecff;
}
.pink {
  background-color: #fff0f5;
}

.text-color {
  color: #777777;
}

.upload-box {
  width: 320px;
  height: 240px;
}

@media (min-width: 1024px) {
  .upload-box {
    width: 320px;
    height: 240px;
  }
}
</style>
