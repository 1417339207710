<template>
  <div v-if="modal.show" class="modal">
    <!-- Modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          @click="closeModal()"
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left
                 overflow-hidden shadow-xl transform relative
                 transition-all sm:my-8 sm:align-middle sm:w-full md:max-w-7xl"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            @click="closeModal()"
            class="absolute top-5 right-5 z-10 cursor-pointer"
          >
            <svg class="w-10 h-10" viewBox="0 0 20 20" fill="#b30000">
              <path
                d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"
              ></path>
            </svg>
          </div>
          <div class="pt-20">
            <ProfileComponent
              :option="option"
              :values="values"
              :defaults="defaults"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileComponent from "@/components/Elements/Profile/Index.vue";

export default {
  name: "Option1PreviewModal",
  props: {
    modal: {
      type: Object,
      required: true,
    },
    option: {
      type: String,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    defaults: {
      type: Object,
      required: true,
    },
  },
  components: { ProfileComponent },
  methods: {
    closeModal() {
      document.body.style.overflow = "visible";
      this.$emit("closeModal", false);
    },
    actionType(action) {
      this.$emit("closeModal", false);
      this.$emit(action);
    },
  },
};
</script>

<style scoped></style>
