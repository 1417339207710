<template>
  <button @click.prevent="$emit('click')">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :class="{
        'yellow-btn': color === 'yellow',
        'purple-btn': color === 'purple',
        'green-btn': color === 'green',
        'pink-btn': color === 'pink',
      }"
      class="h-16 w-16 inline-block"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
        clip-rule="evenodd"
      />
    </svg>
    <p class="text-sm tracking-wide">
      Click to add gif/ Animation
    </p>
  </button>
</template>

<script>
export default {
  name: "GIFAddButton",
  props: {
    color: {
      required: true,
    },
  },
};
</script>

<style scoped>
.green-btn {
  color: #85c6ba;
}
.purple-btn {
  color: #7789e0;
}

.yellow-btn {
  color: #d8c27d;
}

.yellow-btn-bg {
  background-color: #d8c27d;
}

.pink-btn {
  color: #d89cae;
}
</style>
