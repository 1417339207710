<template>
  <div class="chart-container w-60 md:w-96">
    <DoughnutChart :chartData="ttalData" :options="options" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(ChartDataLabels);
Chart.register(...registerables);
export default defineComponent({
  name: "TTALChart",
  components: { DoughnutChart },
  props: {
    chartData: {
      type: Object,
    },
  },
  setup(props) {
    const ttalData = {
      labels: props.chartData.labels,
      datasets: [
        {
          data: props.chartData.data,
          datalabels: {
            color: "white",
            labels: {
              title: {
                font: {
                  weight: "bold",
                  family: "Rajdhani",
                  size: "14",
                },
              },
            },
            display: function (context) {
              let i = context.dataIndex;
              return context.dataset.data[i] !== 0;
            },
            formatter: (value) => {
              return value + "%";
            },
          },
          backgroundColor: ["#FDB400", "#11BCA2", "#FF5670", "#3D50E0"],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          position: "bottom",

          align: "start",
          labels: {
            font: {
              family: "Rajdhani",
              size: 14,
            },
          },
        },
        tooltip: {
          titleFont: {
            family: " Rajdhani",
          },
          bodyFont: {
            family: "Rajdhani",
          },
        },
      },
    };
    return { ttalData, options };
  },
});
</script>
