<template>
  <div class="mx-auto relative" v-if="experienceDetails">
    <div class="text-black flex flex-col h-screen">
      <Navbar />
      <div class="flex items-center justify-between my-4 mx-4 md:mx-10">
        <div class="flex gap-2 items-center">
          <div class="hidden md:block">
            <router-link
              :to="{
                name: 'UserHome',
                params: {
                  company: company,
                },
              }"
            >
              <img
                :src="require('@/assets/images/experiences/button-back.svg')"
              />
            </router-link>
          </div>
          <div class="text-nColorNavbarPurple text-2xl font-bold mx-2 md:mx-0">
            {{ experienceDetails.meta.name }}
          </div>
        </div>
        <div class="hidden md:block">
          <div
            @click.prevent="doneAction"
            class="px-10 py-2 bg-nColorNavbarPurple text-white rounded-lg uppercase font-semibold"
          >
            Done
          </div>
        </div>
      </div>
      <div class="mx-4 md:mx-10 grid gap-4">
        <div class="py-2 px-8 text-justify hidden md:block">
          {{ experienceDetails.meta.description }}
        </div>
        <div class="w-full md:pl-8">
          <EVP
            :expDetails="experienceDetails"
            v-if="experienceDetails.meta.slug == slugsConfig.evp"
            :experience_link_id="experience_link_id"
          />
          <CandidateExpSurvey
            :expDetails="experienceDetails"
            v-else-if="
              experienceDetails.meta.slug == slugsConfig.candidateExpSurvey
            "
            :experience_link_id="experience_link_id"
          />
          <WelcomeVideo
            :expDetails="experienceDetails"
            v-else-if="experienceDetails.meta.slug == slugsConfig.welcomeVideo"
            :experience_link_id="experience_link_id"
          />
          <InductionVideo
            :expDetails="experienceDetails"
            v-else-if="
              experienceDetails.meta.slug == slugsConfig.inductionVideo
            "
            :experience_link_id="experience_link_id"
          />
          <DocumentLibrary
            :expDetails="experienceDetails"
            v-else-if="
              experienceDetails.meta.slug == slugsConfig.documentLibrary
            "
            :experience_link_id="experience_link_id"
          />
          <NHA
            :expDetails="experienceDetails"
            v-else-if="experienceDetails.meta.slug == slugsConfig.nha"
            :experience_link_id="experience_link_id"
          />
          <Welcome360
            :expDetails="experienceDetails"
            v-else-if="experienceDetails.meta.slug == slugsConfig.welcome360"
            :experience_link_id="experience_link_id"
          />
          <TTAL
            :expDetails="experienceDetails"
            v-else-if="experienceDetails.meta.slug == slugsConfig.ttal"
            :experience_link_id="experience_link_id"
          />
          <GTM
            :expDetails="experienceDetails"
            v-else-if="experienceDetails.meta.slug == slugsConfig.gtm"
            :experience_link_id="experience_link_id"
          />
          <ScavengerHunt
            :expDetails="experienceDetails"
            v-else-if="experienceDetails.meta.slug == slugsConfig.scavengerHunt"
            :experience_link_id="experience_link_id"
          />
          <ManagerNPS
            :expDetails="experienceDetails"
            v-else-if="experienceDetails.meta.slug == slugsConfig.managerNPS"
            :experience_link_id="experience_link_id"
          />
          <Pulse1Week
            :expDetails="experienceDetails"
            v-else-if="experienceDetails.meta.slug == slugsConfig.pulseOneWeek"
            :experience_link_id="experience_link_id"
          />
        </div>
        <div class="w-full py-2 px-2 md:px-8 text-justify block md:hidden">
          {{ experienceDetails.meta.description }}
        </div>
      </div>
      <div class="block md:hidden">
        <div class="flex justify-center">
          <div
            @click.prevent="doneAction"
            class="w-9/12 flex justify-center py-2 bg-nColorNavbarPurple text-white rounded-lg uppercase font-semibold"
          >
            Done
          </div>
        </div>
      </div>
      <Footer />
      <FeedbackModal
        :experience_link_id="experience_link_id"
        @closeModal="closeAndRedirect()"
        :modal="modal"
      ></FeedbackModal>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar.vue";
import Footer from "@/components/Common/Footer.vue";
import EVP from "@/components/Experiences/EVP/Index.vue";
import CandidateExpSurvey from "@/components/Experiences/CandidateExpSurvey/Index.vue";
import WelcomeVideo from "@/components/Experiences/WelcomeVideo.vue";
import InductionVideo from "@/components/Experiences/InductionVideo.vue";
import DocumentLibrary from "@/components/Experiences/DocumentLibrary/Index.vue";
import NHA from "@/components/Experiences/NHA/Index.vue";
import Welcome360 from "@/components/Experiences/Welcome360.vue";
import GTM from "@/components/Experiences/GTM/Index.vue";
import TTAL from "@/components/Experiences/TTAL/Index.vue";
import ScavengerHunt from "@/components/Experiences/ScavengerHunt.vue";
import ManagerNPS from "@/components/Experiences/ManagerNPS/Index.vue";
import Pulse1Week from "@/components/Experiences/Pulse1Week/Index.vue";
import FeedbackModal from "@/components/Modals/Feedback.vue";
import slugsConfig from "@/common/slugs";

export default {
  name: "ExperienceDetails",
  components: {
    Navbar,
    Footer,
    EVP,
    CandidateExpSurvey,
    WelcomeVideo,
    InductionVideo,
    DocumentLibrary,
    NHA,
    Welcome360,
    GTM,
    TTAL,
    ScavengerHunt,
    ManagerNPS,
    Pulse1Week,
    FeedbackModal,
  },
  created() {
    ApiService.get(apiResource.experience.getDetails, {
      experience_link_id: this.experience_link_id,
    })
      .then(({ data }) => {
        this.experienceDetails = data.data;
        this.isReady = this.experienceDetails.flags.is_ready;
        this.showFeedback = this.experienceDetails.flags.feedback;
      })
      .catch(() => {
        this.$router.replace({
          name: "NotFound",
          query: { redirect: "/" + this.company },
        });
      });
  },

  data() {
    return {
      experienceDetails: null,
      company: this.$route.params.company,
      experience_link_id: this.$route.params.experience_link_id,
      slugsConfig,
      isReady: false,
      showFeedback: false,
      modal: {
        show: false,
        action: "submit",
      },
    };
  },
  methods: {
    doneAction() {
      if (!this.showFeedback) {
        this.modal.show = true;
      } else {
        this.$router.push({
          name: "UserHome",
          params: { company: this.company },
        });
      }
    },
    closeAndRedirect() {
      this.showFeedback = true;
      this.modal.show = false;
      this.$router.push({
        name: "UserHome",
        params: { company: this.company },
      });
    },
  },
};
</script>

<style scoped lang="postcss"></style>
