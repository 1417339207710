<template>
  <div class="counter">
    <h2
      ref="counterText"
      class="text-xl md:text-5xl font-semibold md:font-bold"
    >
      {{ displayTime.minute }} min {{ displayTime.sec }} sec
    </h2>
  </div>
</template>

<script>
export default {
  name: "Counter",

  props: {
    displayTime: {
      required: true,
    },
  },
};
</script>

<style scoped></style>
