<template>
  <div
    id="content"
    :class="{
      'border border-nColorBlue rounded-xl px-8': started && !finished,
    }"
    class="text-black py-4 mb-8 relative"
    v-if="expDetails"
  >
    <div class="heading relative flex justify-end">
      <div class="timer text-black" v-if="started && !finished">
        <Counter :displayTime="displayTime" />
      </div>
    </div>

    <div class="gtm-start" v-if="leaderBoard">
      <Leaderboard
        v-if="leaderboardContent"
        :leaderboardContent="leaderboardContent"
        :showDuration="true"
      />
    </div>

    <div
      class="start-text text-center bg-nColorBlue mb-8 rounded-xl"
      v-if="!hasPlayed && !started"
    >
      <h1 class="text-9xl font-bold py-8 text-white">Lets Start</h1>
      <button
        class="bg-nButtonGreen px-20 rounded-xl py-4 my-8 text-lg"
        @click="startGTM()"
      >
        Start
      </button>
    </div>
    <div class="gtm-exp" v-else-if="started && !finished">
      <div
        class="movie-show"
        v-for="(movie, index) in movies"
        :key="movie.movie_id"
      >
        <h3
          v-if="movieNumber === index"
          class="absolute -top-7 md:top-4 left-0 md:left-5 text-xl md:text-2xl font-semibold md:font-bold"
        >
          {{ movieNumber + 1 }} / {{ movies.length }}
        </h3>
        <GTMMovie
          @correct="addResult"
          @skip="skipMovie"
          @endGTM="endGTM"
          v-if="movieNumber === index"
          :movie="movie"
          :solution="decodedMovieName[movie.movie_id]"
          :userInputInit="userInput[movie.movie_id]"
        />
      </div>
    </div>

    <div v-else class="gtm-end">
      <div
        class="end-header text-center bg-nButtonGreen py-8 md:py-16 rounded-xl relative"
      >
        <div class="absolute top-0 left-0">
          <lottie-animation
            path="lotties/confetti3.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :height="300"
          />
        </div>
        <div class="absolute top-0 right-0">
          <lottie-animation
            path="lotties/confetti3.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :height="300"
          />
        </div>
        <h1 class="text-9xl font-bold">You did it!</h1>
        <p
          class="text-lg md:text-xl font-semibold mt-4 text-gray-200"
          v-if="indiScore"
        >
          You guessed
          {{ indiScore.score }}/{{ indiScore.total_questions }} movies correctly
          in {{ indiScore.total_duration }}s
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { mapGetters } from "vuex";
import Counter from "@/components/Experiences/GTM/Counter.vue";
import GTMMovie from "@/components/Experiences/GTM/GTMMovie.vue";
import Leaderboard from "@/components/Experiences/Common/Leaderboard.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "GTMExp",
  props: ["expDetails", "experience_link_id"],
  components: {
    GTMMovie,
    Counter,
    Leaderboard,
    LottieAnimation,
  },
  created() {
    this.movies = this.expDetails.exp_data.movies;
    this.leaderBoard = this.expDetails.exp_data.leaderboard;
    this.flags = this.expDetails.flags;
    this.hasPlayed = this.expDetails.exp_data.has_played;
    // if exp is LIVE or user starts playing when its LIVE.
    // if user has played the exp, info.movies does not exist
    if (this.movies) {
      this.movies.forEach((movie) => {
        let wordStringArr = [];
        this.userInput[movie.movie_id] = movie.name.map((word) => {
          let wordString = word
            .map((letter) => String.fromCharCode(letter))
            .join("");
          wordStringArr.push(wordString);
          return new Array(word.length);
        });
        this.decodedMovieName[movie.movie_id] = wordStringArr.join("");
      });
      let totalSeconds = this.movies.length * this.timePerQ;
      this.displayTime.minute = Math.floor(totalSeconds / 60);
      this.displayTime.sec = totalSeconds % 60;
      this.totalTime.minute = this.displayTime.minute;
      this.totalTime.sec = this.displayTime.sec;
    } else {
      return;
    }
  },
  data() {
    return {
      userInput: {},
      decodedMovieName: {},
      movieNumber: 0,
      timePerQ: 10,
      result: [],
      finished: false,
      started: false,
      totalTime: {
        sec: 0,
        minute: 0,
      },
      displayTime: {
        sec: 0,
        minute: 0,
      },
      indiScore: null,
      timer: null,
      leaderBoard: null,
      movies: null,
      flags: null,
      hasPlayed: false,
    };
  },
  methods: {
    startGTM() {
      this.started = true;
      this.countDown();
    },
    skipMovie(answer) {
      answer.time_taken = this.calcTimeTaken();
      this.result.push(answer);
      this.nextMovie();
    },
    addResult(answer) {
      answer.time_taken = this.calcTimeTaken();
      this.result.push(answer);
      this.nextMovie();
    },
    endGTM(answer = null) {
      clearInterval(this.timer);
      if (answer) {
        answer.time_taken = this.calcTimeTaken();
        this.result.push(answer);
      }
      // MAKE API CALL
      ApiService.post(apiResource.obk.addGTMResult, {
        exp_link_id: this.experience_link_id,
        result: this.result,
      })
        .then((data) => {
          this.indiScore = data.data.data.statistics.filter(
            (emp) => emp.employee_id === this.profile.emp_id
          )[0];
          this.leaderboard = data.data.data.statistics;
          this.finished = true;
          this.$emit("played");
        })
        .catch(() => {});
    },
    nextMovie() {
      if (this.movieNumber < this.movies.length - 1) {
        this.movieNumber++;
      } else {
        this.endGTM();
      }
    },
    countDown() {
      this.timer = setInterval(() => {
        if (this.displayTime.minute == 0 && this.displayTime.sec == 0) {
          clearInterval(this.timer);
          this.endGTM();
        } else {
          if (this.displayTime.sec == 0) {
            this.displayTime.sec = 59;
            this.displayTime.minute--;
          } else {
            this.displayTime.sec--;
          }
        }
      }, 1000);
    },
    calcTimeTaken() {
      let totalSeconds = this.totalTime.minute * 60 + this.totalTime.sec;
      let currentSeconds = this.displayTime.minute * 60 + this.displayTime.sec;
      this.totalTime.minute = this.displayTime.minute;
      this.totalTime.sec = this.displayTime.sec;
      return (totalSeconds - currentSeconds).toString();
    },
  },
  computed: {
    ...mapGetters(["profile"]),
    leaderboardContent() {
      if (this.leaderBoard) return this.leaderBoard;
      return null;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
