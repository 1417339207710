<template>
  <div
    class="bookshelves border p-8 border-nColorStrokeGray rounded-xl relative"
  >
    <div class="text-right h-0 sticky top-108">
      <!-- <router-link
        target="_blank"
        :to="{
          name: 'ObkDocumentLibrary',
          params: { obkid: $route.params.id },
        }"
        class="inline-block"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10 text-bgcolor "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
          />
        </svg>
      </router-link> -->
    </div>
    <BookShelf
      v-for="(document, i) in documentsArray"
      :books="document"
      :key="i"
      :experience_link_id="experience_link_id"
    />
  </div>
</template>

<script>
import BookShelf from "@/components/Experiences/DocumentLibrary/BookShelf.vue";

export default {
  name: "DocumentLibraryExp",
  components: { BookShelf },
  props: {
    documents: {
      type: Array,
      required: true,
    },
    experience_link_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documentList: null,
      booksInShelf: this.isMobile() ? 3 : 4,
      documentsArray: [],
    };
  },
  created() {
    this.documentList = [...this.documents];

    this.spliceDocuments(this.documentList);
  },
  computed: {},
  methods: {
    spliceDocuments(documentList) {
      while (documentList.length) {
        this.documentsArray.push(documentList.splice(0, this.booksInShelf));
      }
    },
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.top-108 {
  top: 27rem;
}
</style>
